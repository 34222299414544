<template>
    <el-row>
        <el-dialog title="导入机会" v-if="showObj.isShow" :visible.sync="showObj.isShow" width="60%">
            <el-row>
                <el-col :span="4">数据类型</el-col>
                <el-col :span="15">
                    <el-radio-group v-model="type">
                        <el-radio :label="1">首咨</el-radio>
                        <el-radio :label="4">库存</el-radio>
                    </el-radio-group>
                </el-col>
                <el-divider></el-divider>
            </el-row>
            <el-upload
                    class="upload-demo"
                    ref="upload"
                    :action="url"
                    :headers="headers"
                    :on-preview="handlePreview"
                    :on-remove="handleRemove"
                    :file-list="fileList"
                    :on-success="handleSuccess"
                    :on-error="handleError"
                    :before-upload="handleBefore"
                    :auto-upload="true">
                <el-button slot="trigger" size="small" type="primary">选取文件</el-button>
                <el-button style="margin-left: 10px;" size="small" type="warning" @click="downloadExcel">
                    下载模板
                </el-button>
                <div slot="tip" class="el-upload__tip">只能上传excel文件，且不超过7M大小</div>
                <div slot="tip" class="el-upload__tip" style="font-weight: bold;color: #f56c6c">
                    注意：使用Excel导入时请注意，必须保存唯一配置、项目字段在系统中真实存在，否则存入的字段将为空
                </div>
                <div slot="tip" class="el-upload__tip" v-if="isShowResult">{{ msg }}</div>
                <div slot="tip" class="el-upload__tip" v-if="isShowResult" v-for=" resMsg in msgArr">{{ resMsg }}</div>
            </el-upload>
        </el-dialog>
    </el-row>
</template>

<script>
import axios from "axios";

export default {
    name: "importClue",
    props: {
        pondId: {
            type: Number,
            require: true,
            default: 1
        }
        ,
        showObj: {
            type: Object,
            default: null
        },
        type: { // 1 首咨 2 公海
            type: Number,
            require: true
        },
        token: {
            require: true,
            default: null,
            type: String
        }
    },
    data() {
        return {
            headers: {"version": localStorage.getItem("_version"),},
            fileList: [],
            msgArr: [],
            totalCount: 0,
            successCount: 0,
            isShowResult: false
        }
    },
    methods: {
        submitUpload() {
            this.$refs.upload.submit();
        },
        handleRemove(file, fileList) {
            console.log(file, fileList);
        },
        handlePreview(file) {
            console.log(file);
        },
        /**
         * 下载模板
         */
        async downloadExcel() {
            axios({
                method: "get",
                url: "/api/manage/clue/manage/download-template",
                headers: {
                    "version": localStorage.getItem("_version"),
                },
                responseType: 'blob',
            }).then(res => {
                const blob = new Blob([res.data]);
                const fileName = '机会池导入模板.xlsx';
                const elink = document.createElement('a')
                elink.download = fileName;
                elink.style.display = 'none';
                elink.href = URL.createObjectURL(blob);
                document.body.appendChild(elink);
                elink.click();
                URL.revokeObjectURL(elink.href); // 释放URL 对象
                document.body.removeChild(elink);
            }).catch(error => {
                this.$message.error("导出模板失败");
            })
        },
        /**
         * 图片上传
         */
        handleBefore(file) {
            var testmsg = file.name.substring(file.name.lastIndexOf('.') + 1)
            const extension = testmsg === 'xls'
            const extension2 = testmsg === 'xlsx'
            const isLt2M = file.size / 1024 / 1024 < 7
            if (!extension && !extension2) {
                this.$message({
                    message: '上传文件只能是 xls、xlsx格式!',
                    type: 'warning'
                });
            }
            if (!isLt2M) {
                this.$message({
                    message: '上传文件大小不能超过 7MB!',
                    type: 'warning'
                });
            }
            return extension || extension2 && isLt2M
        },
        /**
         * 上传成功的回调方法
         * @param res
         * @param file
         * @param fileList
         */
        handleSuccess(res, file, fileList) {
            if (res.success) {
                this.$message.success(res.msg)
                // this.msgArr = res.data.msgList
                // this.successCount = res.data.successCount
                // this.totalCount = res.data.totalCount
                this.isShowResult = true
                this.$emit('getOpportunityList')
            } else {
                this.$message.warning(res.msg)
            }
        },
        /**
         * 文件上传失败
         * @param err
         * @param file
         * @param fileList
         */
        handleError(err, file, fileList) {
            this.$message.error("文件上传失败，请联系管理员")
        }
    },
    watch: {
        "showObj.isShow": function (newVal, oldVal) {
            if (newVal) {
                this.msgArr = []
                this.msg = ""
                this.isShowResult = false
            }
        }
    },
    computed: {
        msg: {
            get: function () {
                return `导入顾问机会开始,请稍后在消息中心查看结果`
            },
            set: function (newValue) {
                return newValue
            }
        },
        url: function () {
            return `/api/manage/clue/manage/import-clue/${this.type}?pondId=${this.pondId}`
        }
    }
}
</script>

<style scoped>

</style>
