import request from '@/utils/request.js';

/**
 * 获取学习记录
 * @param params
 * @returns {*}
 */
export function getLearningRecord(params) {
    return request({
        url: '/manage/crm/counselor/learning-record',
        method: 'GET',
        params: params
    })
}

export function getPurchasedGoods(params) {
    return request({
        url: '/manage/crm/counselor/purchased-goods',
        method: 'GET',
        params: params
    })
}


// ====================== 登录 ==================

export function isPermission(permission) {
    return request.get(`/manage/system/role/isPermission/${permission}`)
}

/**
 * 登录
 * @param parms
 * @returns {*}
 */
export function login(parms = {}) {
    return request.get(`/doLogin`, {params: parms})
}

// index
export function index(params = {}) {
    return request.get(`/index`)
}

/**
 * 同意冻结
 * @param userId
 * @param telephone
 * @returns {any}
 */
export function agreeFrozen(userId, telephone, opportunityId, reviewerId) {
    return request.get(`/manage/crm/opporManage/agreeFrozen/${userId}/${telephone}/${opportunityId}/${reviewerId}`)
}

/**
 * 获取机会日志枚举
 */
export function findTypes() {
    return request.get(`/manage/crm/opporManage/findTypes`)
}

/**
 * 获取机会日志枚举
 */
export function findOpporTelephone(telephone) {
    return request.get(`/manage/crm/opporManage/findOpporTelephone/${telephone}`)
}

/**
 * 拒绝冻结
 * @param opportunityId
 * @param userId
 * @param telephone
 * @returns {*}
 */
export function turnDownFrozen(opportunityId, userId, telephone) {
    return request.put(`/manage/crm/opporManage/turnDownFrozen/${opportunityId}/${userId}/${telephone}`)
}

export function custom() {
    return request.get(`/custom`)
}

// ====================== 登录 ==================


// ====================== 公共接口 ==================

/**
 * 获取token
 * @returns {*}
 */
export function token() {
    return request.get(`/common/token`)
}

/**
 * 获取目录
 * @returns {*}
 */
export function menuByLoginUser() {
    return request.get(`/manage/system/menu/menuByLoginUser`)
}

//获取权限树
export function menuTree(param) {
    return request.get(`/manage/system/menu/menuTree`, param)
}

//获取所有菜单
export function menuList(param) {
    return request.get(`/manage/system/menu/menuList`, {params: param})
}

//获取所有菜单列表
export function menuArr() {
    return request.get(`/manage/system/menu/list`)
}

//获取一个菜单
export function getMenu(menuId) {
    return request.get(`/manage/system/menu/getMenu/${menuId}`)
}

//增加菜单
export function addMenu(param) {
    return request({
        method: 'post', url: `/manage/system/menu/addMenu`, data: JSON.stringify(param)
    })
}

//修改菜单
export function updateMenu(param) {
    return request({
        method: 'put', url: `/manage/system/menu/updateMenu`, data: JSON.stringify(param)
    })
}

//删除菜单
export function deleteMenu(menuId) {
    return request.delete(`/manage/system/menu/deleteMenu/${menuId}`)
}

// ====================== 公共接口 ==================


// ====================== 学习顾问页面 ==================

/**
 * 获取头部信息
 * @param parms
 * @returns {*}
 */
export function baseInformation() {
    return request.get(`/manage/crm/counselor/baseInformation`)
}

/**
 * 获取学习顾问统计信息
 * @param params
 */
export function statisticalPage(params) {
    return request({
        url: `/manage/crm/counselor/statisticalPage`, method: 'get', params: params
    })
}

/**
 * 加载领取首咨列表
 */
export function clientList(pondId) {
    return request.get(`/manage/crm/counselor/clientList?page=1&limit=5&pondId=${pondId}`)
}

/**
 * 领取首咨
 * @returns {*}
 */
export function getNewOpportunity(id) {
    return request.post(`/manage/crm/counselor/getNewOpportunity/${id}`)
}

/**
 * 领取机会
 * @param opportunityId 机会ID
 * @param type 来源 0:库存; 1:首咨; 2:校验机会领取; 3:名片首咨的领取; 4:名片公海的领取;
 * @returns {*}
 */
export function receiveOpportunity(opportunityId, type) {
    return request({
        method: 'POST',
        url: `/manage/crm/counselor/receiveOpportunity`,
        params: {
            opportunityId: opportunityId,
            type: type
        }
    })
}

/**
 * 保存机会
 * @param params
 * @returns {*}
 */
export function saveOpportunity(params = {}) {
    return request.post(`/manage/crm/counselor/save`, JSON.stringify(params))
}

/**
 * 获取渠道列表
 * @returns {*}
 */
export function allMediators() {
    return request.get(`/manage/crm/mediatorCut/allMediators`)
}

/**
 * 获取库存列表
 */
export function repertoryListStore(params) {
    return request({
        url: `/manage/crm/counselor/repertoryListStore`, method: 'get', params: params
    })
}

/**
 * 库存列表查询
 */
export function findRepertoryList(params) {
    return request({
        url: `/manage/crm/counselor/findRepertoryList`, method: 'get', params: params
    })
}

/**
 * 拨打
 * @param opporId 机会Id
 * @param source
 * @returns {*}
 */
export function calling(opporId) {
    return request.post(`/manage/crm/traffic/panshiCalling/${opporId}`)
}

/**
 * 双呼拨打
 * @param opporId 机会Id
 * @param source
 * @returns {*}
 */
export function doubleCall(opporId) {
    return request.post(`/manage/crm/traffic/doubleCall/${opporId}`)
}

/**
 * 直接拨打
 * @param phone 手机号
 * @returns {*}
 */
export function cellPhone(phone) {
    return request.get(`/manage/crm/traffic/cellPhone?phone=${phone}`)
}

/**
 * 挂断
 * @returns {*}
 */
export function hangUp() {
    return request.post(`/manage/crm/traffic/hangUp`)
}

/**
 * 冻结
 */
export function frozen(id, freezeTime, type, params = {}) {
    return request.put(`/manage/crm/counselor/freezeOppor/${id}/${freezeTime}/${type}`, JSON.stringify(params))
}

/**
 *
 * @param id
 * @param freezeTime
 * @param type
 * @param params
 * @returns {*}
 */
export function freezePeriod(id, freezeTime) {
    return request.put(`/manage/crm/counselor/freezePeriod/${id}/${freezeTime}`)
}


/**
 * 获取首咨/库存数量
 * @returns {*}
 */
export function statisticsStock(pondId) {
    return request.get(`/manage/crm/counselor/statisticsStock?pondId=${pondId}`)
}

/**
 * 机会校验
 * @param telephone
 * @param pondId
 */
export function checkOpportunityList(telephone, pondId,checked) {
    if(!pondId){
        pondId = 1
    }
    if(!checked){
        checked = false
    }
    return request.get(`/manage/crm/counselor/checkOpportunityList/${telephone}?pondId=${pondId}&checked=${checked}`)
}

/**
 * 查看手机号数量
 */
export function lookPhoneCount() {
    return request.get(`/manage/crm/counselor/lookPhoneCount`)
}

/**
 * 查看手机号数量
 */
export function checkWeChat(id) {
    return request.get(`/manage/crm/counselor/checkWeChat/${id}`)
}

/**
 * 我的机会列表
 * @returns {*}
 */
export function myOpportunity(pageSize, pageNumber, params = {}) {
    return request.post(`/manage/crm/counselor/myOpportunity/${pageSize}/${pageNumber}`, JSON.stringify(params))
}

export function submitHandleConnectFlag(params) {
    return request({
        method: 'PUT',
        url: `/manage/crm/counselor/update/handle-flag`,
        params: params
    })
}

/**
 * 唤醒机会列表
 * @returns {*}
 */
export function wakeOpportunityList(limit, pondId) {
    return request.post(`/manage/crm/counselor/wakeOpportunityList/${limit}?pondId=${pondId}`)
}

/**
 * 领取唤醒机会
 * @param opportunityId
 * @returns {*}
 */
export function drawWakeOpportunity(opportunityId) {
    return request.put(`/manage/crm/counselor/drawWakeOpportunity/${opportunityId}`);
}


/**
 * 批量回库
 * @param idList
 * @returns {*}
 */
export function giveUp(idList) {
    return request.put(`/manage/crm/counselor/giveUp`, idList)
}

/**
 * 回库并增加消息
 * @param idList
 * @param params
 */
export function giveMessageBack(idList, params) {
    params["ids"] = idList
    return request.put(`/manage/crm/counselor/giveMessageBack`, params)
}

/**
 * 专业列表
 * @returns {*}
 */
export function subjectList() {
    return request.get(`/manage/subject/subjectList`)
}

/**
 * 机会详情
 * @param opportunityId
 * @returns {any}
 */
export function opportunityDetail(opportunityId) {
    return request.get(`/manage/crm/counselor/opportunityDetail/${opportunityId}`)
}

/**
 * 我的信息列表
 * @param page
 * @param current
 * @param params
 * @returns {*}
 */
// export function myMessageList(page, current, params = {}) {
//     return request.post(`/manage/msg/messageManage/myMessageList/${page}/${current}`, JSON.stringify(params))
// }

/**
 * 未阅读数量
 * @returns {*}
 */
// export function unReadCount() {
//     return request.get(`/manage/msg/messageManage/unReadCount`)
// }

/**
 * 全部标为已读
 * @returns {*}
 */
// export function markRead() {
//     return request.post(`/manage/msg/messageManage/markRead`)
// }

/**
 * 获取消息的详细内容
 * @param id
 * @returns {any}
 */
// export function contentDetails(id) {
//     return request.get(`/manage/msg/messageManage/contentDetails/${id}`)
// }

// ====================== 学习顾问页面 ==================

// ====================== 数据分析页面==================

/**
 * 数据分析表头
 * @returns {*}
 */
export function indexInfo(params = {}, dataType) {
    return request.post(`/manage/supervisor/index/indexInfo`, JSON.stringify(params))
}

/**
 * 数据分析主体内容
 * @returns {*}
 */
export function superStatisticsCount(params) {
    return request.post(`/manage/supervisor/index/superStatisticsCount`, JSON.stringify(params))
}

/**
 * 学习顾问个人详情页头部数据
 * @param params
 */
export function advisorHeadData(accountId) {
    return request.get(`/manage/supervisor/index/advisorHeadData?accountId=${accountId}`)
}

/**
 * 数据分析学习顾问详情页表格数据
 * @param type
 * @param adviserid
 * @param telephone
 */
export function personInfo(type, advisorId, telephone, page, limit) {
    let url = `/manage/supervisor/index/personInfo?accountId=${advisorId}&opporType=${type}&page=${page}&limit=${limit}`
    if (telephone) {
        url += `&telephone=${telephone}`
    }
    return request.post(url)
}

// ====================== 唯一配置 ==================
export function possessList(id) {
    return request.get(`/manage/crm/possess/list`)

// ====================== 唯一配置 ==================
}

// ====================== 唯一配置 ==================
export function usePossessList(id) {
    return request.get(`/manage/crm/possess/list-use`)

// ====================== 唯一配置 ==================
}

// ====================== 地域 ==================
export function areaListByType(areaType) {
    return request.get(`/manage/system/area/areasByType/${areaType}`)

// ====================== 地域 ==================
}

// ====================== 机会管理  ==================

/**
 * 机会管理列表
 * @param current
 * @param size
 * @param params
 * @returns {*}
 */
export function opporListJson(current, size, params = {}) {
    return request.post(`/manage/crm/opporManage/opporListJson/${current}/${size}`, JSON.stringify(params))
}

/**
 * 获取机会被添加微信列表
 * @returns {*}
 */
export function findAdviserWechat(id) {
    return request.get(`/manage/crm/opporManage/findAdviserWechat/${id}`)
}

/**
 * 成单机会管理列表
 * @param current
 * @param size
 * @param params
 * @returns {*}
 */
export function orderOpporList(current, size, params = {}) {
    return request.post(`/manage/crm/opporManage/orderOpporList/${current}/${size}`, JSON.stringify(params))
}

/**
 * 冻结机会管理列表
 * @param current
 * @param size
 * @param params
 * @returns {*}
 */
export function freezeOpporList(current, size, params = {}) {
    return request.post(`/manage/crm/opporManage/freezeOpporList/${current}/${size}`, JSON.stringify(params))
}

/**
 * 解除机会冻结
 * @param id
 */
export function unfreeze(id) {
    return request.put(`/manage/crm/opporManage/unfreeze/${id}`)
}

/**
 * 分配
 * @param params
 * @returns {*}
 */
export function allot(params = {}) {
    return request.post(`/manage/crm/opporManage/allot`, JSON.stringify(params))
}

/**
 * 修改唯一配置
 * @param params
 * @returns {*}
 */
export function setPossess(params = {}) {
    return request.post(`/manage/crm/opporManage/setPossess`, JSON.stringify(params))
}

/**
 * 批量修改唯一配置
 * @param params
 * @returns {*}
 */
export function updateBatchPosses(params = {}) {
    return request.put(`manage/crm/opporManage/updateBatchPossesId`, JSON.stringify(params))
}

/**
 * 查看手机号
 * @param params
 * @returns {*}
 */
export function showTelephone(params) {
    return request.post(`/manage/crm/opporManage/showTelephone`, JSON.stringify(params))
}

/**
 * 名片首咨列表
 * @param current
 * @param size
 * @param params
 * @returns {*}
 */
export function firstOpporListJson(current, size, params = {}) {
    return request.post(`/manage/crm/firstconsult/opporListJson/${current}/${size}`, JSON.stringify(params))
}

/**
 * 领取机会
 * @param opportunityId 机会ID
 * @param type 来源 0:库存; 1:首咨; 2:校验机会领取; 3:名片首咨的领取; 4:名片公海的领取;
 * @returns {*}
 */
export function draw(opportunityId, type) {
    return request({
        method: 'POST',
        url: `/manage/crm/firstconsult/draw`,
        params: {
            opportunityId: opportunityId,
            type: type
        }
    })
}

/**
 * 保存首咨
 * @param params
 * @returns {*}
 */
export function saveFirstConsult(params = {}) {
    return request.post(`/manage/crm/firstconsult/save`, JSON.stringify(params));
}


/**
 * 名片公海列表
 * @param current
 * @param size
 * @param params
 * @returns {*}
 */
export function cardSeaOpporListJson(current, size, params = {}) {
    return request.post(`/manage/crm/cardSea/opporListJson/${current}/${size}`, JSON.stringify(params))
}

/**
 * 将删除的机会拉回
 * @param params
 * @returns {Promise<AxiosResponse<any>>}
 */
export function resetOpportunity(params) {
    return request.post(`/manage/crm/cardSea/resetOpportunity`, JSON.stringify(params))
}

export function toRecycleBin(params) {
    return request.post(`/manage/crm/cardSea/toRecycleBin`, JSON.stringify(params))
}

// ====================== 机会管理  ==================

// ====================== 唯一配置 ==================
export function configureList(current, size, params = {}) {
    return request.post(`/manage/crm/configure/oneListJson/${current}/${size}`, JSON.stringify(params))
}

/**
 * 获取顾问配置详情
 * @param configureId
 */
export function getConfigure(configureId) {
    return request.get(`/manage/crm/configure/get/${configureId}`)
}

/**
 * 添加或者修改configure
 * @param params
 * @param type
 */
export function saveOrUpdateConfigure(params, type) {
    switch (type) {
        case 1:
            return request.post(`/manage/crm/configure/save`, JSON.stringify(params))
        case 2:
            return request.put(`/manage/crm/configure/update`, JSON.stringify(params))
    }
}

export function deleteById(id) {
    return request.delete(`/manage/crm/configure/delete?id=${id}`)
}

//获取顾问配置列表`

// ====================== 唯一配置==================

//======================= 学习顾问详情 start ============
/**
 * 学习顾问信息详情
 **/
export function customerMessage(id) {
    return request.get(`manage/crm/counselor/customerMessage/${id}`)
}

/**
 * 设置机会的沟通进度
 */
export function setCommunicationProgress(id, str, opportunityType) {
    return request.put(`manage/crm/counselor/setCommunicationProgress/${id}/${opportunityType}`, str)
}

/**
 * 学习顾问详情--历史记录
 **/
export function history(id, type) {
    return request.get(`manage/crm/counselor/historyList/${id}/${type}`)
}

/**
 * 学习顾问详情--信息修改
 **/
export function saveMessage(id, parms = {}) {
    return request.post(`manage/crm/counselor/saveMessage/${id}`, parms)
}

/**
 * 学习顾问详情--预约信息新增
 **/
export function appointmentAdd(id, parms = {}) {
    return request.post(`manage/crm/appointment/appointmentAdd/${id}`, parms)
}

/**
 * 学习顾问详情--预约信息列表
 **/
export function appointmentList(id) {
    return request.get(`manage/crm/appointment/appointmentList/${id}`)
}

/**
 * 学习顾问详情--支付信息查询
 **/
export function getPayInformation(id) {
    return request.get(`manage/order/payInformation/getPayInformation/${id}`)
}

/**
 * 学习顾问详情--下单页面
 **/
export function goodList(page, limit, params = {}) {
    return request.post(`manage/crm/order/goodList/${page}/${limit}`, params)
}

/**
 * 学习顾问详情--提交订单
 **/
export function createOrder(params = {}) {
    return request.post(`manage/crm/order/createOrder`, params)
}

/**
 * 手动创建订单
 **/
export function CreateManuallyOrder(params = {}) {
    return request.post(`manage/crm/order/CreateManuallyOrder`, params)
}

/**
 * 手动修改订单
 **/
export function UpdateManuallyOrder(params = {}) {
    return request.post(`manage/crm/order/UpdateManuallyOrder`, params)
}


/**
 * 学习顾问详情--提交订单
 **/
export function clinchOrder(params = {}) {
    return request.post(`manage/crm/order/clinchOrder`, params)
}

/**
 * 学习顾问详情--获取支付方式
 **/
export function paySuccess(params = {}) {
    return request.post(`manage/crm/order/paySuccess`, params)
}

/**
 * 获取国家省份
 **/
export function areasByType(areaType) {
    return request.get(`manage/system/area/areasByType/${areaType}`)
}

/**
 * 光荣榜--获取数据列表
 **/
export function rankingList(params = {}) {
    return request.post(`manage/supervisor/index/rankingList`, params)
}

/**
 * 获取光荣榜配置
 */
export function rankingConfig() {
    return request.get(`manage/supervisor/index/rankingConfig`)
}


// ====================== 小组管理  ==================

/**
 * 部门管理列表
 * @returns {any}
 */
export function deptManageList() {
    return request.get(`manage/system/dept/deptManagerList`);
}

/**
 * 部门下学习顾问列表
 * @param current
 * @param size
 * @param deptId
 * @returns {*}
 */
export function deptStatistics(current, size, deptId, params) {
    return request.post(`/manage/supervisor/index/deptStatistics/${current}/${size}/${deptId}`, JSON.stringify(params));
}

/**
 * 获取小组管理的头部数据
 * @param deptId
 * @returns {*}
 */
export function deptHeadSatist(deptId, params) {
    return request.post(`/manage/supervisor/index/deptHeadSatirist/${deptId}`, JSON.stringify(params))
}

/**
 * 拨打明细列表
 */
export function trafficRecordList(page, size, params = {}) {
    return request.post(`/manage/crm/traffic/trafficRecordList/${page}/${size}`, JSON.stringify(params));
}

/**
 * 查询拨打合计信息
 * @param params
 * @returns {Promise<AxiosResponse<any>>}
 */
export function sumTraffic(params) {
    return request.post(`/manage/crm/traffic/sumTraffic`, JSON.stringify(params));
}

export function customerAdviserList(pageSize, pageNumber, params = {}) {
    return request.post(`/manage/crm/customer/customerAdviserList/${pageSize}/${pageNumber}`, JSON.stringify(params))
}

/**
 * 获取用户完整手机号
 */
export function getTelephone(id) {
    return request.get(`manage/crm/customer/getTelephone?id=` + id)
}

// ====================== 小组管理  ==================

/**
 * 导出数据
 * @param params
 * @returns {Promise<AxiosResponse<any>>}
 */
export function operationLog(params) {
    return request.post(`/manage/dashboard/operationLog`, JSON.stringify(params), {
        timeout: 1000 * 60 * 5
    })
}

/**
 * 查询统计
 * @param params
 * @returns {Promise<AxiosResponse<any>>}
 */
export function operationLogPage(current, size, params) {
    return request.post(`/manage/dashboard/operationLogPage/${current}/${size}`, JSON.stringify(params))
}

/**
 * 查询统计
 * @param params
 */
export function exportOperation(params) {
    return request.post(`/manage/dashboard/operationLog`, JSON.stringify(params))
}

/**
 * 初始化系统版本号
 */
export function initVersion() {
    return request.get(`/version`)
}
