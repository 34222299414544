<template>
  <div>
    <el-dialog :destroy-on-close="true" append-to-body
               title="补录订单"
               :visible="dialogValue"
               @close="cancel"
               width="80%">
      <div>
        <el-form ref="formRules" :inline="true" label-position="right"  :model="form"
                 label-width="130px">
          <div style="font-size: 18px;font-weight: 600;margin-bottom: 10px">基本信息</div>
          <el-form-item :class="showUpdateColumn('opporId')" label="机会ID" prop="opporId" v-if="classifyData.opporId && classifyData.opporId.status"  :rules="classifyData.opporId && classifyData.opporId.required == true ? formRules.opporId : []">
            <el-input v-model="form.opporId" size="mini" type="number" :disabled="repairUpdate"></el-input>
          </el-form-item>

          <el-form-item  :class="showUpdateColumn('phone')" label="手机号" prop="phone" v-if="classifyData.phone  && classifyData.phone.status"  :rules="classifyData.phone? formRules.phone : []">
            <el-input v-model="form.phone" size="mini" :disabled="repairUpdate"></el-input>
          </el-form-item>

          <el-form-item :class="showUpdateColumn('goodsName')" label="购买商品名称" prop="goodsIdArr" v-if="classifyData.goodsIds && classifyData.goodsIds.status"  :rules="classifyData.goodsIds ? formRules.goodsIdArr : []">
            <div style="display: flex;flex-direction: column" v-if="form.goodsName">
              <el-tag type="info" v-for="item in form.goodsName.split('&')" :key="item" style="margin-top: 10px">
                {{ item }}
              </el-tag>
            </div>
            <el-button size="mini" :disabled="repairUpdate" @click="getCheckedGoods">绑定商品</el-button>
          </el-form-item>

          <el-form-item label="原价" prop="price" v-if="classifyData.price && classifyData.price.status"  :rules="classifyData.price && classifyData.price.required == true ? formRules.price_ : []" :class="showUpdateColumn('price')">
            <el-input-number v-model="form.price_" :disabled="repairUpdate" size="mini" type="number" :min="1"
                             label="描述文字"></el-input-number>
          </el-form-item>

          <el-form-item :class="showUpdateColumn('hasPrinter')" label="是否包含打印机" prop="hasPrinter" v-if="classifyData.hasPrinter  && classifyData.hasPrinter.status"  :rules="classifyData.hasPrinter && classifyData.hasPrinter.required == true ? formRules.hasPrinter : []">
              <el-badge value="new" class="item">
                  <el-select v-model="form.hasPrinter" placeholder="请选择" size="mini" :disabled="repairUpdate" >
                      <el-option label="包含" :value="1"/>
                      <el-option label="不包含" :value="0"/>
                      <el-option label="旧数据" :value="2" disabled/>
                  </el-select>
              </el-badge>
          </el-form-item>
          <!--    补录信息-->
          <div style="font-size: 18px;font-weight: 600;margin-bottom: 10px">支付信息</div>
          <el-form-item :class="showUpdateColumn('adviserId')" label="归属学习顾问" prop="adviserId" v-if="classifyData.adviserId  && classifyData.adviserId.status"  :rules="classifyData.adviserId ? formRules.adviserId : []">
            <el-select style="width: 100%" v-model="form.adviserId" :disabled="repairUpdate"
                       placeholder="请选择学习顾问" size="mini" filterable >
              <el-option
                  v-for="item in adviserList"
                  :key="item.id"
                  :label="item.nickName"
                  :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item :class="showUpdateColumn('submitId')" label="提交人" prop="submitId"  v-if=" repairUpdate">
              <div v-for="item in adviserList">
                  <el-tag v-if="item.id === form.submitId" >{{item.nickName}}</el-tag>
              </div>
          </el-form-item>

          <el-form-item :class="showUpdateColumn('payType')" label="支付渠道" prop="payType" v-if="classifyData.payType  && classifyData.payType.status"  :rules="classifyData.payType ? formRules.payType : []">
            <el-radio-group v-model="form.payType" :disabled="repairUpdate">
              <div >
                <el-radio v-for="item in payTypeArr" :key="item.id" :label="item.dictValue"
                  :disabled="item.dictStatus === 1">
                  {{ item.dictLabel }}
                </el-radio>
              </div>
            </el-radio-group>
          </el-form-item>

          <el-row>
            <el-form-item :class="showUpdateColumn('payAccount')" label="收款账号" prop="payAccount" v-if="classifyData.payAccount  && classifyData.payAccount.status"  :rules="classifyData.payAccount && classifyData.payAccount.required == true ? formRules.payAccount : []">
              <el-select style="width: 100%" v-model="form.payAccount" :disabled="repairUpdate"
                         placeholder="请选择收款账号" size="mini" >
                <el-option
                    v-for="item in payAccountArr"
                    :key="item.id"
                    :label="item.dictLabel"
                    :value="item.dictValue"
                    :disabled="item.dictStatus === 1">
                </el-option>
              </el-select>
            </el-form-item>

            <el-form-item :class="showUpdateColumn('orderNo')" label="支付单号" prop="orderNo" v-if="classifyData.orderNo  && classifyData.orderNo.status"  :rules="classifyData.orderNo? formRules.orderNo : []">
              <el-input v-model.trim="form.orderNo" size="mini" :disabled="repairUpdate"
                        style="width: 400px; line-height: normal"></el-input>
              <el-tooltip class="item" effect="dark" content="复制" placement="top-start">
                <el-button style="margin-left: 10px" icon="el-icon-document-remove" circle size="mini"
                           v-clipboard:copy="form.orderNo"
                           v-clipboard:success="onCopy"
                           v-clipboard:error="onError"></el-button>
              </el-tooltip>
            </el-form-item>
          </el-row>

          <el-row>
            <el-form-item :class="showUpdateColumn('payPrice')" label="付款金额" prop="payPrice" v-if="classifyData.payPrice  && classifyData.payPrice.status"  :rules="classifyData.payPrice ? formRules.payPrice_ : []">
              <el-input-number v-model="form.payPrice_" :disabled="repairUpdate" size="mini" :min="1"
                               label="描述文字"></el-input-number>
            </el-form-item>

            <el-form-item :class="showUpdateColumn('payTime')" label="支付时间" prop="payTime" v-if="classifyData.payTime  && classifyData.payTime.status"  :rules="classifyData.payTime ? formRules.payTime : []">
              <el-date-picker
                  v-model="form.payTime"
                  type="date"
                  :disabled="repairUpdate"
                  size="mini"
                  value-format="yyyy-MM-dd"
                  :picker-options="pickerOptions"
                  placeholder="选择日期时间" >
              </el-date-picker>
            </el-form-item>
          </el-row>

          <el-row>
            <el-form-item label="订单分类" prop="orderChannel" v-if="classifyData.orderChannel  && classifyData.orderChannel.status"  :rules="classifyData.orderChannel && classifyData.orderChannel.required == true ? formRules.orderChannel : []">
              <el-select v-model="form.orderChannel" size="mini" :disabled="repairUpdate">
                <el-option v-for="item in orderChannelArr"
                           :key="item.id"
                           :value="item.dictValue"
                           :label="item.dictLabel"
                           :disabled="item.dictStatus === 1"></el-option>
              </el-select>
            </el-form-item>

              <el-form-item label="是否参加活动" prop="joinActivity" v-if="classifyData.joinActivity  && classifyData.joinActivity.status"  :rules="classifyData.joinActivity && classifyData.joinActivity.required == true ? formRules.joinActivity : []">
                  <el-select v-model="form.joinActivity" size="mini" :disabled="repairUpdate">
                      <el-option :value="true" label="参加"/>
                      <el-option :value="false" label="未参加"/>
                  </el-select>
              </el-form-item>

              <el-form-item label="活动" prop="activityId" v-if="form.joinActivity">
                  <el-select v-model="form.activityId" size="mini" :disabled="repairUpdate">
                      <el-option v-for="item in activityList"
                                 :key="item.id"
                                 :value="Number(item.dictValue)"
                                 :label="item.dictLabel"
                                 :disabled="item.dictStatus === 1"></el-option>
                  </el-select>
              </el-form-item>
          </el-row>

          <el-row>
            <el-form-item :class="showUpdateColumn('notes')" label="订单备注" prop="notes" v-if="classifyData.notes  && classifyData.notes.status"  :rules="classifyData.notes && classifyData.notes.required == true ? formRules.notes : []">
                <el-badge value="new" class="item">
                    <el-input type="textarea" :row="10" autosize placeholder="必要时可输入备注内容" maxlength="100" v-model="form.notes" :disabled="repairUpdate" />
                </el-badge>
            </el-form-item>

            <el-form-item label="是否邮寄教材" v-if="(classifyData.isMailTxbooks && classifyData.isMailTxbooks.status) || form.isMailTxbooks" :rules="classifyData.isMailTxbooks && classifyData.isMailTxbooks.required == true ? formRules.isMailTxbooks : []">
              <el-select v-model="form.isMailTxbooks" size="mini" :disabled="repairUpdate">
                <el-option :value="true" label="是"/>
                <el-option :value="false" label="否"/>
              </el-select>
            </el-form-item>

            <el-form-item :class="showUpdateColumn('mailTxbooks')" label="邮寄教材" prop="mailTxbooks" v-if="form.isMailTxbooks">
                <el-select v-model="form.mailTxbooks" multiple size="mini" :disabled="repairUpdate">
                <el-option v-for="item in mailTxBooksArr"
                           :key="item.id"
                           :value="item.dictValue"
                           :label="item.dictLabel"
                           :disabled="item.dictStatus === 1"></el-option>
              </el-select>
            </el-form-item>

            <el-form-item :class="showUpdateColumn('mailAddr')" label="邮寄地址" prop="mailAddr" v-if="form.isMailTxbooks">
              <el-input type="textarea" :row="10" autosize placeholder="必要时可输入邮寄地址" maxlength="100" v-model="form.mailAddr" :disabled="repairUpdate" />
            </el-form-item>

            <el-form-item :class="showUpdateColumn('handleOpenCourse')" label="是否手动开课" v-if="classifyData.handleOpenCourse && classifyData.handleOpenCourse.status" :rules="classifyData.handleOpenCourse && classifyData.handleOpenCourse.required == true ? formRules.handleOpenCourse : []">
              <el-select v-model="form.handleOpenCourse" size="mini" :disabled="repairUpdate">
                <el-option :value="true" label="是"/>
                <el-option :value="false" label="否"/>
              </el-select>
            </el-form-item>
        </el-row>
            <el-row>
            <el-form-item :class="showUpdateColumn('rebateAmount')" label="返佣金额" prop="rebateAmount" v-if="classifyData.rebateAmount  && classifyData.rebateAmount.status"  :rules="classifyData.rebateAmount && classifyData.rebateAmount.required == true ? formRules.rebateAmount_ : [] ">
              <el-input-number v-model="form.rebateAmount_" :disabled="repairUpdate" size="mini" :min="0"
                               label="描述文字">
              </el-input-number>
            </el-form-item>

            <el-form-item :class="showUpdateColumn('studentName')" label="学员姓名" prop="studentName" v-if="classifyData.studentName  && classifyData.studentName.status"  :rules="classifyData.studentName && classifyData.studentName.required == true ? formRules.studentName : null">
                <el-input type="textarea" :row="10" autosize placeholder="必要时可输入学员姓名" maxlength="100" v-model="form.studentName" :disabled="repairUpdate" />
            </el-form-item>

          </el-row>

          <el-row>
            <el-form-item label="支付截图" prop="orderImgs" v-if="classifyData.orderImgs  && classifyData.orderImgs.status"  :rules="classifyData.orderImgs ? formRules.orderImgs : []">

              <div style="display: flex;flex-direction: row;">

                <el-upload :disabled="repairUpdate"
                           class="upload-demo"
                           action="/api/manage/file/uploadImg"
                           :headers="headers"
                           :on-success="(res,fileList)=>handleAvatarSuccess(res,fileList,0)"
                           :on-preview="handlePictureCardPreview"
                           :on-remove="(res,fileList)=>handleRemove(res, fileList,0)"
                           :file-list="fileList"
                           :on-error="handleError"
                           list-type="picture-card">
                  <el-button size="small" type="primary" :disabled="repairUpdate">点击上传</el-button>
                  <div slot="tip" class="el-upload__tip">只能上传.jpg/.png文件，且不超过5MB</div>
                </el-upload>

                <div v-if="!repairUpdate">
                  <p>示例截图</p>
                  <el-image :src="require('../../assets/images/payExample.jpg')"
                            :preview-src-list="[require('../../assets/images/payExample.jpg')]" :z-index="3000"
                            mode="cover" style="width: 100px;height: auto"/>
                </div>

              </div>


            </el-form-item>
          </el-row>

          <el-row>
            <!--          一审可见可编辑-->
            <!--          二审可见不可编辑-->
            <!--          firstSuggestDisa-->
            <el-col :span="24">
              <el-form-item label="一审证明类型" prop="proveType" v-if="firstSuggestSync">
                <el-select style="width: 50vw"
                           :disabled="firstSuggestDisa"
                           v-model="form.proveType"
                           multiple
                           placeholder="请选择证明类型">
                  <el-option
                      v-for="(item,index) in proveTypeArr"
                      :key="index"
                      :label="item.label"
                      :value="item.value"
                      :disabled="item.disabled">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>

            <el-col :span="24">
              <el-form-item label="一审审批意见" prop="firstSuggest" v-if="firstSuggestSync">
                <el-input style="width: 50vw" type="textarea" :rows="5" placeholder="请输入意见"
                          :disabled="firstSuggestDisa"
                          v-model="form.firstSuggest"></el-input>
              </el-form-item>
            </el-col>

            <el-col :span="24">
              <el-form-item label="一审意见回执" prop="firstSuggestImgs" v-if="firstSuggestSync">
                <el-upload :disabled="firstSuggestDisa"
                           class="upload-demo"
                           action="/api/manage/file/uploadImg"
                           :headers="headers"
                           :on-success="(res,fileListBack1)=>handleAvatarSuccess(res,fileListBack1,1)"
                           :on-preview="handlePictureCardPreview"
                           :on-remove="(res,fileListBack1)=>handleRemove(res, fileListBack1,1)"
                           :file-list="fileListBack1"
                           :on-error="handleError"
                           list-type="picture-card">
                  <el-button size="small" :disabled="firstSuggestDisa">点击上传<br><br>回执图片</el-button>
                  <div slot="tip" class="el-upload__tip">只能上传.jpg/.png文件，且不超过5MB</div>
                </el-upload>
              </el-form-item>
            </el-col>


          </el-row>

          <el-row>
            <!--          二审可见可编辑-->
            <el-col :span="24">
              <el-form-item label="财务审批意见" prop="secondSuggest" v-if="secondSuggestSync">
                <el-input style="width: 50vw" type="textarea" :rows="5" placeholder="请输入意见"
                          :disabled="secondSuggestDisa"
                          v-model="form.secondSuggest"></el-input>
              </el-form-item>
            </el-col>

            <el-col :span="24">
              <el-form-item label="二审意见回执" prop="secondSuggestImgs" v-if="secondSuggestSync">
                <el-upload :disabled="secondSuggestDisa"
                           class="upload-demo"
                           action="/api/manage/file/uploadImg"
                           :headers="headers"
                           :on-success="(res,fileListBack2)=>handleAvatarSuccess(res,fileListBack2,2)"
                           :on-preview="handlePictureCardPreview"
                           :on-remove="(res,fileListBack2)=>handleRemove(res, fileListBack2,2)"
                           :file-list="fileListBack2"
                           :on-error="handleError"
                           list-type="picture-card">
                  <el-button size="small" :disabled="secondSuggestDisa">点击上传<br><br>回执图片</el-button>
                  <div slot="tip" class="el-upload__tip">只能上传.jpg/.png文件，且不超过5MB</div>
                </el-upload>
              </el-form-item>
            </el-col>


          </el-row>


        </el-form>
        <div slot="footer" style="text-align: end" class="dialog-footer">
          <el-popconfirm
              v-if="examineYes"
              title="审核通过后，将对此单子的真实性负责到底。请认真核对!"
              @confirm="examineSubmit"
              style="margin-right: 10px"
          >
            <el-button slot="reference" type="primary"
                       size="mini">通 过
            </el-button>
          </el-popconfirm>


          <el-button type="primary" @click="examineBack"
                     v-if="examineNo"
                     size="mini">
            驳
            回
          </el-button>
          <el-button type="primary" @click="beforeSubmitRepair" v-if="!repairUpdate" size="mini">提 交</el-button>
          <el-button type="primary" @click="complaintSubmitRepair" v-if="complaintSubmit" size="mini">申诉提交</el-button>
          <el-button @click="cancel" size="mini">取消</el-button>
        </div>
      </div>
    </el-dialog>

    <el-dialog :visible.sync="dialogVisible" append-to-body>
      <img width="100%" :src="dialogImageUrl" alt="">
    </el-dialog>


    <el-dialog
        title="扫码添加业务审核人员企业微信"
        :visible.sync="addFirstSuggestPeopledialogVisible"
        width="60%" append-to-body>
      <el-image :src="require('@/assets/images/addFirstSuggestPeople2.png')" fit="contain"></el-image>
      <span slot="footer">
    <el-button type="primary" @click="submitRepair">确 定</el-button>
  </span>
    </el-dialog>

    <el-drawer
        title="已绑定商品数据列表"
        :visible.sync="checkedGoodsDrawer"
        direction="rtl"
        size="60%"
        style="max-height: 100%; overflow: auto;"
        :before-close="handleClose">
      <el-table height="75vh" :data="checkedGoodsArr">
        <el-table-column property="repairName" label="补录商品名称" width="300"></el-table-column>
        <el-table-column property="price" label="价格" width="150">
            <template v-slot="scope">
                <template v-if="scope.row.price">
                    {{(scope.row.price / 100).toFixed(2)}}元
                </template>
            </template>
        </el-table-column>
        <el-table-column
            label="商品图片"
            prop="cover">
          <template v-slot="scope">
            <el-image
                style="width: 100px; height: 100px"
                :src="scope.row.cover ? scope.row.cover : noImgUrl"
                fit="cover"></el-image>
          </template>
        </el-table-column>
        <el-table-column property="createByName" label="创建人"></el-table-column>
        <el-table-column
            fixed="right"
            label="操作"
            width="100">
          <template slot-scope="scope">
            <el-button
                @click.native.prevent="deleteChecked(scope.row.id)"
                type="text"
                size="small">
              移除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <div style="width: 100%; text-align: center">
        <el-divider direction="vertical"></el-divider>
        <el-button type="primary" style="width: 30%" @click="checkNewGoods">绑定新商品</el-button>
        <el-divider direction="vertical"></el-divider>
        <el-button type="success" style="width: 30%" @click="saveCheckGoods">保存</el-button>
        <el-divider direction="vertical"></el-divider>
      </div>


      <div>
        <el-drawer
            title="绑定新商品"
            size="50%"
            :append-to-body="true"
            :visible.sync="checkNewGoodsDrawer">
          <el-form :inline="true" :model="goodsQueryParams" class="demo-form-inline">
            <el-form-item label="补录商品名称">
              <el-input v-model="goodsQueryParams.repairName" placeholder="请输入补录商品名称"></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="checkNewGoods">查询</el-button>
            </el-form-item>
          </el-form>
          <el-table height="75vh" :data="checkGoodsArr">
            <el-table-column property="repairName" label="补录商品名称" width="300"></el-table-column>
            <el-table-column property="price" label="价格" width="150">
                <template v-slot="scope">
                    {{(scope.row.price / 100).toFixed(2)}}
                </template>
            </el-table-column>
            <el-table-column
                label="商品图片"
                prop="cover">
              <template v-slot="scope">
                <el-image
                    style="width: 100px; height: 100px"
                    :src="scope.row.cover ? scope.row.cover : noImgUrl"
                    fit="cover"></el-image>
              </template>
            </el-table-column>
            <el-table-column property="createByName" label="创建人"></el-table-column>
            <el-table-column
                fixed="right"
                label="操作"
                width="120">
              <template slot-scope="scope">
                <el-button
                    @click.native.prevent="checkGoods(scope.row)"
                    type="text"
                    size="small">
                  绑定
                </el-button>
              </template>
            </el-table-column>
          </el-table>
          <pagination
              :v-show="goodsQueryParams.total>10"
              :total="goodsQueryParams.total"
              :page.sync="goodsQueryParams.current"
              :limit.sync="goodsQueryParams.size"
              @pagination="checkNewGoods"
          />
          <el-button style="width: 100%" type="info" @click="checkNewGoodsDrawer = false">已完成</el-button>
        </el-drawer>
      </div>
    </el-drawer>

  </div>


</template>

<script>
import * as repairApi from "@/api/crm/orderRepair";
import {getUserId} from "@/utils/auth";
import * as dectApi from '@/api/system/dictionary'
import {getByIds, getListPageExcludeIds, list} from '@/api/crm/crmGoods'
import noCover from "@/assets/images/noImg.png";
import {submitRepairId} from "@/api/crm/orderRepair";
import * as configApi from "@/api/system/config";
import * as searchApi from "@/api/system/searchDeploy";

export default {
    name: "RepairOrder",
    props: {
        repairId: {
            type: Number
        },
        repairStatus: {
            type: Number
        },
        dialogValue: {
            type: Boolean,
            default: false
        },
        examineType: {
            type: Number
        },
        adviserList: {
            type: Array,
            default: []
        },
        pondId: {
            type: Number,
            default:1
        }
    },
    data() {
        var validateNumber = (rule, value, callback) => {
            if (value.length > 9) {
                callback(new Error('请输入正确的机会ID'))
            }
            if (!(/^[+]{0,1}(\d+)$|^[+]{0,1}(\d+\.\d+)$/).test(value)) {
                callback(new Error('请输入数字值'))
            } else {
                callback()
            }
        };
        return {
            headers:{"version": localStorage.getItem("_version"),},
            formRules: {
                opporId: [
                    {required: true, message: '请输入机会ID', trigger: 'blur'},
                    {validator: validateNumber, trigger: 'blur'}
                ],
                phone: [{required: true, message: '请输入手机号', trigger: 'blur'}],
                adviserId: [{required: true, message: '请选择学习顾问', trigger: 'blur'}],
                goodsIdArr: [{required: true, message: '请选择商品名称', trigger: 'blur'}],
                payPrice_: [{required: true,
                    validator: (rule, value, callback) =>{
                        if(this.form.payPrice_ == null){
                            callback(new Error('请输入付款金额'))
                        }else{
                            callback()
                        }
                    }
                    , trigger: 'blur'}],

                rebateAmount_: [{required: true,
                    validator: (rule, value, callback) =>{
                        if(this.form.rebateAmount_ == null){
                            callback(new Error('请输入返佣金额'))
                        }else{
                            callback()
                        }
                    }
                    , trigger: 'blur'}],
                price_: [{required: true,
                    validator: (rule, value, callback) =>{
                        if(this.form.price_ == null){
                            callback(new Error('请输入原价'))
                        }else{
                            callback()
                        }
                    }, trigger: 'blur'}],
                payTime: [{required: true, message: '请选择支付时间', trigger: 'blur'}],
                payChannel: [{required: true, message: '请选择支付渠道', trigger: 'blur'}],
                payType: [{required: true, message: '请选择支付方式', trigger: 'blur'}],
                orderNo: [{required: true, message: '请输入支付单号', trigger: 'blur'}],
                payAccount: [{required: true, message: '请选择收款账号', trigger: 'blur'}],
                orderChannel: [{required: true, message: '请选择订单分类', trigger: 'blur'}],
                // proveType: [{require: true, message: '请选择证明类型', trigger: 'blur'}]
                hasPrinter: [{required: true, message: '请选择是否包含打印机', trigger: 'blur'}],
                notes: [{required: true, message: '请输入订单备注', trigger: 'blur'}],
                mailAddr: [{required: true, message: '请输入邮寄地址', trigger: 'blur'}],
                mailTxbooks: [{required: true, message: '请输入邮寄教材', trigger: 'blur'}],
                studentName: [{required: true, message: '请输入学员姓名', trigger: 'blur'}],
                handleOpenCourse: [{required: true, message: '请选择是否手动开课', trigger: 'blur'}],
                isMailTxbooks: [{required: true, message: '请输入是否邮寄', trigger: 'blur'}],
                joinActivity: [{required: true, message: '请输入是否参加活动', trigger: 'blur'}],
                orderImgs: [{ required: true,validator: (rule, value, callback) =>{
                    if(this.fileList.length === 0){
                        callback(new Error('请上传支付截图'))
                    }else{
                        callback()
                    }
                    }, trigger: "blur" },
                ],

            },
            dialogVisible: false,
            dialogImageUrl: null,
            // 判断是更新还是新增.默认为新增
            repairUpdate: false,
            shwocode:false,
            firstSuggestSync: false,
            secondSuggestSync: false,
            firstSuggestDisa: false,
            secondSuggestDisa: false,
            suggest: null,
            examineBackImgs: [],
            examineYes: true,
            complaintSubmit: false,
            examineNo: true,
            fileListBack1: [],
            fileListBack2: [],
            REPAIR_ADD_WECHAT: {},
            queryParams: {},
            loadProveType: null,
            classifyData: {
                opporId:{
                status:null
            }},
            form: {
                adviserId:null,
                goodsName: '',
                goodsIdArr: [],
                firstSuggest: null,
                secondSuggest: null,
                opporId: null,
                hasPrinter: null,
                notes: null,
                price_: 1,
                rebateAmount_:null,
                isMailTxbooks:false,
                mailTxbooks:[],
                studentName:null,
                joinActivity: '',
                activityId:'',
                handleOpenCourse: '',
            },
            // 学习顾问数组数据
            counselorArr: [],
            fileList: [],
            selectOrderDialogVisible: false,
            selectOrderTableData: [],
            orderChannelArr: [],
            mailTxBooksArr:[],
            payTypeArr: [],
            payAccountArr: [],
            courseNameList: [],
            currentRow: null,
            // adviserList: [],
            proveTypeArr: [
                {value: '0', label: '无需证明', disabled: false},
                {value: '1', label: '电话截图记录证明', disabled: false},
                {value: '2', label: '微信聊天证明', disabled: false},
                {value: '3', label: '飞鸽聊天记录证明', disabled: false},
                {value: '4', label: '录音聊天证明', disabled: false},
                {value: '9', label: '早期数据,未填写', disabled: true}
            ],
            pickerOptions: {
                disabledDate(time) {
                    return time.getTime() > Date.now();
                }
            },
            addFirstSuggestPeopledialogVisible: false,
            checkedGoodsDrawer: false,
            checkNewGoodsDrawer: false,
            checkedGoodsArr: [],
            checkGoodsArr: [],
            noImgUrl: noCover,
            goodsQueryParams: {
                current: 1,
                size: 10,
                total: 0
            },
            status: null,
            updatedColumns:[],
            activityList:[],
        }
    },
    created() {
    this.findByClassifyForMap();
  },
    mounted() {
        this.init();
        // 获取cookie中用户id放入form表单中
        // 获取学习顾问列表
        // this.findAdviserList()
        // 初始化更新的补录订单数据
        this.initRepair()
      this.form.adviserId = getUserId()
    },
    methods: {
      showUpdateColumn(column) {
        if (this.updatedColumns && this.updatedColumns.length > 0 && this.updatedColumns.indexOf(column) > -1) {
            return '_update-label'
          }
        },
        submitRepairId,
        // 初始化数据
        init() {
            this.findConfig();
            // 收款账号数据
            dectApi.list({dictParent: 14}).then(resp => {
                if (resp.success) {
                    this.payAccountArr = resp.data
                } else this.$message.error(resp.msg)
            })
            // 支付渠道数据
            dectApi.list({dictParent: 15}).then(resp => {
                if (resp.success) {
                    this.payTypeArr = resp.data
                } else this.$message.error(resp.msg)
            })
            // 订单分类数据
            dectApi.list({dictParent: 16}).then(resp => {
                if (resp.success) {
                    this.orderChannelArr = resp.data
                } else this.$message.error(resp.msg)
            })
            dectApi.list({dictParent: 150}).then(resp => {
                if (resp.success) {
                    this.mailTxBooksArr = resp.data
                } else this.$message.error(resp.msg)
            })
            dectApi.list({dictParent: 19}).then(resp => {
                if (resp.success) {
                    this.activityList = resp.data
                } else this.$message.error(resp.msg)
            })
            // 商品名称数据
            list().then(resp => {
                if (resp.success) {
                    this.courseNameList = resp.data
                }
            })
        },
        zebj() {
            var dataKey = document.cookie.replace(/(?:(?:^|.*;\s*)dataKey\s*\=\s*([^;]*).*$)|^.*$/, "$1");
            // console.log("dataKey:"+dataKey)
            //zhengerbajing huaxiawomi
            if (dataKey === 'huaxiawomi') {
                 this.shwocode = true;
            }

        },

    findByClassifyForMap() {
        let type = 'REPAIR_INFO';
        searchApi.findByClassifyForMap(type).then(res => {
        if (res.success) {
            // console.log("classify:"+JSON.stringify(res));
            this.classifyData = res.data;
            // console.log("opporId status:"+this.classifyData.opporId.status);
            // console.log("opporId required:"+this.classifyData.opporId.required);

        }
      });
    },
            //获取配置列表
    findConfig() {
      configApi.findConfig().then(res => {
        if (res.success) {
        // console.log("res:"+JSON.stringify(res))
        // console.log("res:"+JSON.stringify(res.data.REPAIR_ADD_WECHAT))
          this.REPAIR_ADD_WECHAT = res.data.REPAIR_ADD_WECHAT ? res.data.REPAIR_ADD_WECHAT : {};
          // console.log("res:"+JSON.stringify(this.REPAIR_ADD_WECHAT))
          // console.log("res:"+this.REPAIR_ADD_WECHAT.qrcode)
        }
      });
    },
        onCopy(e) {    // 复制成功
            this.$message({
                message: '复制成功！',
                type: 'success'
            })
        },
        onError(e) {   // 复制失败
            this.$message({
                message: '复制失败！',
                type: 'error'
            })
        },
        /**
         * 图片预览
         * @param file
         */
        handlePictureCardPreview(file) {
            this.dialogImageUrl = file.url;
            this.dialogVisible = true;
        },
        /**
         * 审核通过
         * 审核通过需要记录审核意见
         */
        examineSubmit() {
            if (this.form.status === 1) {
                this.suggest = this.form.firstSuggest
                this.examineBackImgs = this.fileListBack1;
            } else if (this.form.status === 2) {
                this.suggest = this.form.secondSuggest
                this.examineBackImgs = this.fileListBack2;
            }
            //图片上传
            let imgs = []
            for (let item of this.examineBackImgs) {
                imgs.push(item.url)
            }

            let backImgs = imgs.join(",");
            let proveTypeStr = this.form.proveType.join(',');
            repairApi.examineSubmit(this.repairId, this.suggest, backImgs, proveTypeStr, this.status).then(res => {
                if (res.success) {
                    this.$message.success(res.msg)
                    this.$emit('update:dialogValue', false)
                    this.$parent.getList()

                } else {
                    this.$message.error(res.msg)
                }
            })
        },
        /**
         * 审核驳回
         */
        examineBack() {
            if (this.form.status === 1) {
                this.suggest = this.form.firstSuggest
                this.examineBackImgs = this.fileListBack1
            } else if (this.form.status === 2) {
                this.suggest = this.form.secondSuggest
                this.examineBackImgs = this.fileListBack2
            }
            //图片上传
            let imgs = []
            for (let item of this.examineBackImgs) {
                imgs.push(item.url)
            }

            let backImgs = imgs.join(",")
            let proveTypeStr = this.form.proveType.join(',');
            repairApi.examineBack(1, this.repairId, this.suggest, backImgs, proveTypeStr).then(res => {
                if (res.success) {
                    this.$message.success(res.msg)
                    this.$emit('update:dialogValue', false)
                    this.$parent.getList()

                } else {
                    this.$message.error(res.msg)
                }
            })
        },
        initRepair() {
            // 更新需要禁用页面数据修改,只允许编辑审核意见
            this.repairUpdate = false // 不禁用
            this.firstSuggestSync = false // 不显示
            this.examineYes = false
            this.examineNo = false
            // 审核
            if (this.repairStatus === 2) {
                //如果是审核订单
                repairApi.examineRepair(this.repairId).then(res => {
                    if (res.success) {
                        // 过滤
                        res.data.payAccount = String(res.data.payAccount)
                        this.form = Object.assign({}, res.data)
                        this.form.payPrice_ = this.form.payPrice / 100
                        if(this.form.rebateAmount == 0){
                            this.form.rebateAmount_ = 0
                        }else{
                            this.form.rebateAmount_ = this.form.rebateAmount / 100
                        }
                        this.form.price_ = this.form.price / 100
                        this.form.goodsIdArr = this.form.goodsIds.split(",").map(Number)
                        if(this.form.mailTxbooks){
                            this.form.mailTxbooks=this.form.mailTxbooks.split(",")
                        }
                        if (this.form.proveType) {
                            this.form.proveType = this.form.proveType.split(",")
                        }
                        this.status = this.form.status
                        this.fileList = []
                        if (this.form.orderImgs) {
                            if (this.form.orderImgs && this.form.orderImgs.length > 1) {
                                let orderImgs = this.form.orderImgs.split(",")
                                if (orderImgs) {
                                    for (let item of orderImgs) {
                                        let sp = item.split("/");
                                        this.fileList.push({"name": sp[sp.length - 1], "url": item})
                                    }
                                }
                            }
                        }

                        this.fileListBack1 = []
                        if (this.form.firstSuggestImgs !== null && this.form.firstSuggestImgs.length > 1) {
                            let firstSuggestImgs = this.form.firstSuggestImgs.split(",")
                            if (firstSuggestImgs) {
                                for (let item of firstSuggestImgs) {
                                    let sp = item.split("/");
                                    this.fileListBack1.push({"name": sp[sp.length - 1], "url": item})
                                }
                            }
                        }

                        this.fileListBack2 = []
                        if (this.form.secondSuggestImgs !== null && this.form.secondSuggestImgs.length > 1) {
                            let secondSuggestImgs = this.form.secondSuggestImgs.split(",")
                            if (secondSuggestImgs) {
                                for (let item of secondSuggestImgs) {
                                    let sp = item.split("/");
                                    this.fileListBack2.push({"name": sp[sp.length - 1], "url": item})
                                }
                            }
                        }
                        if (this.form.status === 1) {
                            this.firstSuggestSync = true
                            this.firstSuggestDisa = false
                        } else if (this.form.status === 2) {
                            this.firstSuggestSync = true
                            this.firstSuggestDisa = true
                            this.secondSuggestSync = true
                            this.secondSuggestDisa = false
                        }
                        this.repairUpdate = true
                        this.examineYes = true
                        this.examineNo = true
                        this.updatedColumns = Object.keys(res.data.updatedColumns)
                    } else {
                        this.$message.error(res.msg)
                        this.$emit('update:dialogValue', false)
                    }
                })
            }
            // 修改
            else if (this.repairStatus === 1) {
                repairApi.getRepair(this.repairId).then(res => {
                    if (res.success) {
                        // 过滤
                        res.data.payAccount = String(res.data.payAccount)
                        this.form = Object.assign({}, res.data)
                        this.form.payPrice_ = this.form.payPrice / 100
                        if(this.form.rebateAmount == 0){
                            this.form.rebateAmount_ = 0
                        }else{
                            this.form.rebateAmount_ = this.form.rebateAmount / 100
                        }
                        this.form.price_ = this.form.price / 100
                        this.form.goodsIdArr = this.form.goodsIds.split(",").map(Number)
                        if(this.form.mailTxbooks){
                            this.form.mailTxbooks=this.form.mailTxbooks.split(",")
                        }
                        if (this.form.proveType) {
                            this.form.proveType = this.form.proveType.split(",")
                        }
                        this.fileList = []
                        if (this.form.orderImgs && this.form.orderImgs.length > 1) {
                            let orderImgs = this.form.orderImgs.split(",")
                            if (orderImgs) {
                                for (let item of orderImgs) {
                                    let sp = item.split("/");
                                    this.fileList.push({"name": sp[sp.length - 1], "url": item})
                                }
                            }
                        }

                        this.fileListBack1 = []
                        if (this.form.firstSuggestImgs !== null && this.form.firstSuggestImgs.length > 1) {
                            let firstSuggestImgs = this.form.firstSuggestImgs.split(",")
                            if (firstSuggestImgs) {
                                for (let item of firstSuggestImgs) {
                                    let sp = item.split("/");
                                    this.fileListBack1.push({"name": sp[sp.length - 1], "url": item})
                                }
                            }
                        }

                        this.fileListBack2 = []
                        if (this.form.secondSuggestImgs !== null && this.form.secondSuggestImgs.length > 1) {
                            let secondSuggestImgs = this.form.secondSuggestImgs.split(",")
                            if (secondSuggestImgs) {
                                for (let item of secondSuggestImgs) {
                                    let sp = item.split("/");
                                    this.fileListBack2.push({"name": sp[sp.length - 1], "url": item})
                                }
                            }
                        }
                        this.firstSuggestSync = true
                        this.firstSuggestDisa = true
                        this.secondSuggestSync = true
                        this.secondSuggestDisa = true
                        this.repairUpdate = false
                        this.examineYes = false
                        this.examineNo = false

                    } else {
                        this.$message.error(res.msg)
                        this.$emit('update:dialogValue', false)
                    }
                })
            }
            // 查看详情
            else if (this.repairId) {
                repairApi.getRepair(this.repairId).then(res => {
                    if (res.success) {
                        // 过滤
                        res.data.payAccount = String(res.data.payAccount)

                        this.form = Object.assign({}, res.data)
                        this.form.payPrice_ = this.form.payPrice / 100
                        if(this.form.rebateAmount == 0){
                            this.form.rebateAmount_ = 0
                        }else{
                            this.form.rebateAmount_ = this.form.rebateAmount / 100
                        }
                        this.form.price_ = this.form.price / 100
                        this.form.goodsIdArr = this.form.goodsIds.split(",").map(Number)
                        if(this.form.mailTxbooks){
                            this.form.mailTxbooks=this.form.mailTxbooks.split(",")
                        }
                        if (this.form.proveType) {
                            this.form.proveType = this.form.proveType.split(",")
                        }
                        this.fileList = []
                        if (this.form.orderImgs && this.form.orderImgs.length > 1) {
                            let orderImgs = this.form.orderImgs.split(",")
                            if (orderImgs) {
                                for (let item of orderImgs) {
                                    let sp = item.split("/");
                                    this.fileList.push({"name": sp[sp.length - 1], "url": item})
                                }
                            }
                        }

                        this.fileListBack1 = []
                        if (this.form.firstSuggestImgs !== null && this.form.firstSuggestImgs.length > 1) {
                            let firstSuggestImgs = this.form.firstSuggestImgs.split(",")
                            if (firstSuggestImgs) {
                                for (let item of firstSuggestImgs) {
                                    let sp = item.split("/");
                                    this.fileListBack1.push({"name": sp[sp.length - 1], "url": item})
                                }
                            }
                        }

                        this.fileListBack2 = []
                        if (this.form.secondSuggestImgs !== null && this.form.secondSuggestImgs.length > 1) {
                            let secondSuggestImgs = this.form.secondSuggestImgs.split(",")
                            if (secondSuggestImgs) {
                                for (let item of secondSuggestImgs) {
                                    let sp = item.split("/");
                                    this.fileListBack2.push({"name": sp[sp.length - 1], "url": item})
                                }
                            }
                        }
                        // 只要是查看详情进来的
                        // 都可以查看意见内容,都不可编辑
                        this.firstSuggestSync = true
                        this.secondSuggestSync = true
                        this.firstSuggestDisa = true
                        this.secondSuggestDisa = true
                        // 禁用编辑录单内容
                        this.repairUpdate = true
                        // 不显示 通过按钮 与 驳回按钮
                        this.examineYes = false
                        this.examineNo = false
                        console.log(222,this.repairStatus)
                        if(this.repairStatus === 10){
                          //显示申诉提交按钮
                            this.complaintSubmit = true;
                        }
                    } else {
                        this.$message.error(res.msg)
                        this.$emit('update:dialogValue', false)
                    }


                })
            }
        },
        cancel() {
            this.$emit('update:dialogValue', false)
        },
        /**
         * 申诉提交
         */
        complaintSubmitRepair(){
            console.log("申诉提交");
            repairApi.complaintSubmit(this.repairId).then(res =>{
                if (res.success) {
                    this.$message.success(res.msg)
                    this.$emit('update:dialogValue', false)
                    this.$parent.getList()
                }else{
                    this.$message.error(res.msg)
                }
            })
        },
        /**
         * 提交
         */
        beforeSubmitRepair() {
            console.log("预提交");
            if (this.form.joinActivity && !this.form.activityId) {
                this.$message.error('请选择参加的活动');
                return;
            }
            if (this.form.isMailTxbooks &&  this.form.mailTxbooks.length === 0) {
                this.$message.error('请至少选择一件邮寄素材');
                return;
            }
            this.$refs['formRules'].validate((valid) => {
                console.log("预提交进入");
                if (valid) {
                    console.log("验证通过");
                    console.log(this.shwocode);
                    // let b = this.zebj();
                    // this.zebj();
                    // this.findConfig();
                    console.log("repair_add_wechat:"+this.REPAIR_ADD_WECHAT);
                    console.log("repair_add_wechat:"+this.REPAIR_ADD_WECHAT.qrcode);
                    if(this.REPAIR_ADD_WECHAT.qrcode == 'OFF'){
                        console.log("提交数据"+this.shwocode);
                        this.addFirstSuggestPeopledialogVisible = false
                        this.submitRepair();
                    }else{
                        console.log("弹框"+this.shwocode);
                        this.addFirstSuggestPeopledialogVisible = true
                    }
                } else {
                    this.$message.error("请正确填写信息!")
                }
            })
            console.log("预提交结束");
        },
        submitRepair() {
            this.$refs['formRules'].validate((valid) => {
                if (valid) {
                    console.log("验证通过 提交");
                    this.addFirstSuggestPeopledialogVisible = false

                    let _obj = Object.assign({}, this.form)

                    _obj.payPrice = this.form.payPrice_ * 100
                    if(this.form.rebateAmount_ == 0){
                        _obj.rebateAmount = 0
                        }else{
                            _obj.rebateAmount = this.form.rebateAmount_ * 100
                        }
                        _obj.price = this.form.price_ * 100
                        _obj.orderNo = this.form.orderNo.replaceAll(" +", "")
                        _obj.goodsIds = this.form.goodsIdArr.join(',');
                    if(this.form.mailTxbooks){
                        _obj.mailTxbooks=this.form.mailTxbooks.join(",")
                    }
                    if(this.loadProveType == null){
                        this.loadProveType = this.form.proveType;
                    }
                    if (this.form.proveType) {
                        _obj.proveType = this.loadProveType.join(',');
                    }
                    //图片上传
                    let imgs = []
                    for (let item of this.fileList) {
                        imgs.push(item.url)
                    }
                    _obj.orderImgs = imgs.join(",")

                    console.log(this.form)
                    _obj['pondId'] = this.pondId
                    repairApi.submitRepair(_obj).then(res => {
                        if (res.success) {
                            this.$message.success(res.msg)
                            this.$emit('update:dialogValue', false)
                            this.$parent.getList()
                        } else {
                            // this.form.payPrice = this.form.payPrice / 100
                            // this.form.price = this.form.price / 100
                            this.$message.error(res.msg)
                        }
                    }).catch(e => {
                        // this.form.payPrice = this.form.payPrice / 100
                        // this.form.price = this.form.price / 100
                        this.$message.error("发生异常请联系开发人员!")
                    })
                } else {
                    this.$message.error("请正确填写信息!")
                }
            })
        },
        // findAdviserList() {
        //     accountApi.adviserList().then(res => {
        //         this.adviserList = res.data
        //     })
        // },
        checkOrder(row) {
            repairApi.checkOrderNo(row.order_id).then(res => {
                if (res.success) {
                    this.form.orderNo = row.order_id
                    this.form.goodsName = row.title
                    // this.form.payPrice = row.price
                    this.form.orderCreateTime = row.created_time
                    this.form.payTime = row.pay_time
                    this.form.xiaoeUserId = row.user_id
                    this.form.resourceId = row.resource_id
                    this.form.goodsImg = row.goods_img
                    this.form.clientType = row.client_type
                    this.form.price = row.price + row.discount_price
                    this.form.userId = row.user_id
                    this.form.orderChannel = 'XIAOETONG'
                    repairApi.getUserPhone(row.user_id).then(ress => {
                        if (ress.code === 0) {
                            this.form.phone = ress.data.phone
                            this.selectOrderDialogVisible = false
                        } else {
                            this.$message.error("无法确认用户!")
                        }
                    })
                } else {
                    this.$message.error(res.msg)
                }
            })
        },
        //金额格式化
        numberformat(number) {
            number = (number + '').replace(/[^0-9+-Ee.]/g, '');
            var n = !isFinite(+number) ? 0 : +number,
                prec = !isFinite(+2) ? 0 : Math.abs(2),
                s = '',
                toFixedFix = function (n, prec) {
                    var k = Math.pow(10, prec);
                    return '' + Math.floor(n * k) / k;
                };
            s = (prec ? toFixedFix(n, prec) : '' + Math.floor(n)).split('.');
            if ((s[1] || '').length < prec) {
                s[1] = s[1] || '';
                s[1] += new Array(prec - s[1].length + 1).join('0');
            }
            return s.join('.');
        },
        getCollectionWay(type) {
            let v = this.collectionWayList.get(type)
            return v ? v : "无";
        },
        handleQuery() {
            repairApi.xiaoeOrderList(this.queryParams).then(res => {
                if (res.code === 0) {
                    this.selectOrderTableData = res.data.list
                } else {
                    this.$message.error(res.msg)
                }
            })
        },

        handleRemove(file, fileList, type) {
            switch (type) {
                case 0:
                    this.fileList = fileList;
                    break;
                case 1:
                    this.fileListBack1 = fileList;
                    break;
                case 2:
                    this.fileListBack2 = fileList;
                    break;
            }

        },
        handleAvatarSuccess(res, file, type) {
            let sp = res.msg.split("/");
            switch (type) {
                case 0:
                    this.fileList.push({"name": sp[sp.length - 1], "url": res.msg})
                    break;
                case 1:
                    this.fileListBack1.push({"name": sp[sp.length - 1], "url": res.msg})
                    break;
                case 2:
                    this.fileListBack2.push({"name": sp[sp.length - 1], "url": res.msg})
                    break;
            }
        },
        handleError(event) {
          // console.log(event)
            this.$message.error("图片上传失败")
        },
        getCheckedGoods() {
            let ids = '';
            if (this.form.goodsIdArr) {
                ids = this.form.goodsIdArr.join(',');
            }
            getByIds(ids).then(resp => {
                if (resp.success) {
                    this.checkedGoodsArr = resp.data;
                    this.checkedGoodsDrawer = true;
                } else {
                    this.$message.error(resp.msg)
                }
            })
        },
        deleteChecked(goodsId) {
            this.form.goodsIdArr = this.form.goodsIdArr.filter(e => {
                return e !== goodsId
            });
            this.checkedGoodsArr = this.checkedGoodsArr.filter(e => {
                if (goodsId !== e.id) {
                    return e;
                }
            })
        },
        checkNewGoods() {
            this.checkNewGoodsDrawer = true;
            let params = {
                ids: '',
                repairName: this.goodsQueryParams.repairName,
                current: this.goodsQueryParams.current,
                size: this.goodsQueryParams.size
            };
            if (this.form.goodsIdArr) {
                params.ids = this.form.goodsIdArr.join(',');
            }
            getListPageExcludeIds(params).then(resp => {
                if (resp.success) {
                    this.checkGoodsArr = resp.data.data;
                    this.goodsQueryParams.total = resp.data.count
                    this.goodsQueryParams.current = resp.data.current
                    this.goodsQueryParams.size = resp.data.size
                }
            })
        },
        checkGoods(goods) {
            this.checkedGoodsArr.push(goods);
            this.checkNewGoodsDrawer = false;
        },
        saveCheckGoods() {
            this.form.goodsIdArr = this.checkedGoodsArr.map(e => e.id)
            this.$set(this.form, 'goodsName', this.checkedGoodsArr.map(e => e.repairName).join('&'));
            this.checkedGoodsDrawer = false;
        },
        handleClose(done) {
            this.$confirm('确认关闭？')
                .then(_ => {
                    done();
                })
                .catch(_ => {
                });
        }
    }
}
</script>

<style lang="less" scoped>
#abc {
  width: 100%;
  height: 100vh;
  background: url("../../assets/images/background.png") 50% 0 /cover;
}

.custom--order-info-wrapper {
  display: flex;
  flex-direction: column;
  background-color: #eeeeee;
  padding: 10px 20px 20px 20px;

  .order-item-box {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;

    .order-item {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 400px;
      font-size: 16px;
    }
  }
}

/deep/ .avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

/deep/ .avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}

/deep/ .avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

/deep/ .avatar {
  width: 178px;
  height: 178px;
  display: block;
}

/deep/ ._update-label {
  .el-form-item__label {
    color: red !important;
  }
}
</style>
