<template>
    <div style="display: inline-block">
        <!--        Select 选择器-->
        <el-select v-if="item.type === 'select'" :multiple="item.multiple" v-model="ruleForm[item.prop]"
                   :allow-create="item.allowCreate" :filterable="item.filterable"
                   :default-first-option="item.defaultFirstOption"
                   :placeholder="item.placeholder">
            <el-option v-for="item in item.optionData" :key="item.value" :label="item.label"
                       :value="item.value"></el-option>
        </el-select>
        <!--      Radio 单选框-->
        <el-radio-group v-else-if="item.type === 'radio'" v-model="ruleForm[item.prop]">
            <el-radio :label="item.value" v-for="item in item.optionData" :key="item.value">{{ item.label }}</el-radio>
        </el-radio-group>
        <!--      Input 输入框  -->
        <el-input v-else-if="item.type === 'input'" v-model="ruleForm[item.prop]"
                  :placeholder="item.placeholder" :type="item.inputType || 'text' "
                  :disabled="item.disabled"></el-input>
        <!--    DatePicker 日期选择器-->
        <el-date-picker
                v-else-if="item.type === 'datePicker'"
                v-model="ruleForm[item.prop]"
                :type="item.dateType"
                value-format="yyyy-MM-dd 00:00:00"
                format="yyyy-MM-dd"
                :placeholder="item.placeholder">
        </el-date-picker>

        <el-select v-else-if="item.type === 'dict'" :multiple="item.multiple" v-model="ruleForm[item.prop]"
                   :filterable="true" :default-first-option="true"
                   :placeholder="item.placeholder">
            <el-option v-for="item in item.optionData" :key="item.id" :label="item.dictLabel"
                       :value="item.dictValue"></el-option>
        </el-select>
        <el-select v-else-if="item.type === 'orderType'" :multiple="item.multiple" v-model="ruleForm[item.prop]"
                   :filterable="true" :default-first-option="true"
                   :placeholder="item.placeholder">
            <el-option v-for="item in item.optionData" :key="item.id" :label="item.name"
                       :value="item.id"></el-option>
        </el-select>

        <template v-if="item.type === 'combo'">
            <!--    组合方式-->
            <ReceiveItem v-model="ruleForm[item.prop][item2.prop]" class="combo-list" v-if="isShowItem(item2)"
                         v-for="item2 in item.comboList" :key="item2.prop"
                         :item="item2" @input="oninput(item2, $event)" :style="item2.style"/>
        </template>
    </div>
</template>


<script>
import * as dictApi from '@/api/system/dictionary'
import * as receiveApi from "@/api/system/receive"
import * as pondApi from "@/api/clue/cluePond";

export default {
    name: 'ReceiveItem',
    props: {
        item: {
            type: Object,
            default: () => {
            }
        },
        value: null
    },
    data() {
        return {
            ruleForm: {},
            optionsMap: {}
        }
    },
    watch: {
        ruleForm: {
            deep: true,
            immediate: true,
            handler(nVal, oVal) {
                // console.log('监听到新的数据', nVal)
                this.$emit('input', nVal[this.item.prop])
            }
        },
        value: {
            deep: true,
            immediate: true,
            handler(nVal) {
                // if (this.item.type === 'combo') {
                //   console.log('123123123123', nVal)
                // }
                this.$set(this.ruleForm, this.item.prop, nVal)
            }
        }
    },
    beforeMount() {
        this.initCombo()
    },
    computed: {
        /**
         * 根据code获取下拉框参数
         * @param code
         */
        findOptions(code) {
            receiveApi.findOptions(code).then(res => {
                if (res.success) {
                    this.optionsMap[code] = res.data?.map(item => {
                        item.value = String(item.value)
                        return item
                    }) || []
                }
            })
        },
        isShowItem() {
            return (item) => {
                if (item.hasOwnProperty('linkage')) {
                    if (!item.linkage.prop) {
                        return false
                    }
                    //
                    // console.log('变化', this.ruleForm)
                    if (item.linkage.values && Array.isArray(item.linkage.values)) {
                        return item.linkage.values.indexOf(this.ruleForm[this.item.prop][item.linkage.prop]) > -1
                    }
                    return this.ruleForm[this.item.prop][item.linkage.prop] === item.linkage.value;

                }
                return true
            }
        }
    },
    methods: {
        oninput(item, data) {
            this.ruleForm[this.item.prop][item.prop] = data
            this.ruleForm = Object.assign({}, this.ruleForm)
            this.$emit('input', this.ruleForm[this.item.prop])
            // this.$nextTick(() => {
            //     this.ruleForm = Object.assign({}, this.ruleForm)
            //     this.$emit('input', this.ruleForm[this.item.prop])
            // })

        },
        /*
        * 初始化组合式数据项
        * */
        initCombo() {
            if (this.item.type === 'combo') {
                this.ruleForm[this.item.prop] = {}
            }
            if (this.item.type === 'dict') {
                dictApi.findChildrenByCode(this.item.dict).then(res => {
                    this.item.optionData = res.data
                })
            }
            if(this.item.type === 'orderType'){
                pondApi.listCluePond().then(res => {
                    if (res.success) {
                        this.item.optionData = res.data
                        this.item.optionData.push({id:1,name:'基础'})
                    }
                })
            }
        }
    }
}
</script>

<style scoped lang="less">
.combo-list:not(:first-child) {
  margin-left: 10px;
}
</style>
