import request from '@/utils/request.js';

var url = "/manage/waiter/student";


/**
 * 分页查询
 * @param params
 */
export function findPage(params) {
    return request({
        url: `${url}/findPage`, method: 'get', params: params
    })
}

/**
 * 分页查询我的学员
 * @param params
 */
export function findMyPage(params) {
    return request({
        url: `${url}/findMyPage`, method: 'get', params: params
    })
}

/**
 * 保存数据(新增/修改)
 * @param params
 */
export function saveData(params) {
    return request.post(`${url}/saveData`, JSON.stringify(params))
}

/**
 * 分配
 * @param servicesId
 * @param idList
 */
export function allot(servicesId, idList) {
    return request.post(`${url}/allot?servicesId=${servicesId}`, JSON.stringify(idList))
}

/**
 * 获取详情
 * @param studentId
 */
export function info(studentId) {
    return request({
        url: `${url}/info/${studentId}`, method: 'get'
    })
}

/**
 * 获取手机号
 * @param studentId
 */
export function viewMobile(studentId) {
    return request({
        url: `${url}/viewMobile/${studentId}`, method: 'get'
    })
}

/**
 * 查询学员机会记录
 * @param params
 */
export function history(params) {
    return request({
        url: `${url}/history`, method: 'get', params: params
    })
}

/**
 * 查询订单
 * @param params
 */
export function findStudentOrder(params) {
    return request({
        url: `${url}/findStudentOrder`, method: 'get', params: params
    })
}

/**
 * 根据学员ID查询学员订单
 * @param studentId
 */
export function getOrderByStudentId(studentId) {
    return request({
        url: `${url}/getOrderByStudentId/${studentId}`, method: 'get'
    })
}

/**
 * 获取未回访学员数
 * @param servicesId
 */
export function notAccess(servicesId) {
    return request({
        url: `${url}/notAccess/${servicesId}`, method: 'get'
    })
}

/**
 * 删除学员
 * @returns
 */
export function removeStudent(idList){
    return request({method:'delete',url:`${url}/removeStudent`,data: JSON.stringify(idList)})
}

/**
 * 删除订单
 * @returns
 */
export function deleteOrder(orderId){
    return request({method:'delete',url:`${url}/deleteOrder/${orderId}`})
}

/**
 * 根据机会ID查询学员
 * @param opporId
 */
export function getByOpporId(opporId) {
    return request({
        url: `${url}/getByOpporId/${opporId}`, method: 'get'
    })
}