<template>
  <div class="app-container">
    <el-row :gutter="20">
      <!--部门数据-->
      <el-col :span="4" :xs="24">
        <div class="head-container">
          <el-tree
              highlight-current
              :data="deptOptions"
              :expand-on-click-node="false"
              :filter-node-method="filterNode"
              :props="defaultProps"
              node-key="id"
              ref="tree"
              default-expand-all
              @node-click="handleNodeClick"
          />
        </div>
      </el-col>
      <!--用户数据-->
      <el-col :span="20" :xs="24">
        <el-form :model="queryParams" ref="queryForm" :inline="true" v-show="showSearch" label-width="68px">
          <el-form-item label="昵称" prop="nickName">
            <el-input
                v-model="queryParams.nickName"
                placeholder="请输入昵称"
                clearable
                size="small"
                style="width: 240px"
                @keyup.enter.native="handleQuery"
            />
          </el-form-item>
          <el-form-item label="登录账号" prop="userName">
            <el-input
                v-model="queryParams.userName"
                placeholder="请输入登录账号"
                clearable
                size="small"
                style="width: 240px"
                @keyup.enter.native="handleQuery"
            />
          </el-form-item>
          <el-form-item label="真实姓名" prop="realName">
            <el-input
                v-model="queryParams.realName"
                placeholder="请输入真实姓名"
                clearable
                size="small"
                style="width: 240px"
                @keyup.enter.native="handleQuery"
            />
          </el-form-item>
          <el-form-item label="手机号码" prop="telephone">
            <el-input
                v-model="queryParams.telephone"
                placeholder="请输入手机号码"
                clearable
                size="small"
                style="width: 240px"
                @keyup.enter.native="handleQuery"
            />
          </el-form-item>
          <el-form-item label="状态" prop="status">
            <el-select
                v-model="queryParams.status"
                filterable
                placeholder="用户状态"
                clearable
                size="small"
                style="width: 240px"
            >
              <el-option value="" label="全部"></el-option>
              <el-option v-for="status in statusOptions" :value="status.value"
                         :label="status.label"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="角色" prop="roleIds">
            <el-select
                v-model="queryParams.roleIds"
                filterable
                placeholder="选择角色"
                clearable
                size="small"
                style="width: 240px"
            >
              <el-option v-for="role in roleOptions" :key="role.id" :value="role.id"
                         :label="role.roleName"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="学习顾问身份" prop="isAdviser" label-width="100">
            <el-select
                v-model="queryParams.isAdviser"
                placeholder="学习顾问身份"
                filterable
                clearable
                size="small"
                style="width: 240px"
            >
              <el-option label="全部" value=""></el-option>
              <el-option label="学习顾问" value="1"></el-option>
              <el-option label="非学习顾问" value="0"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="顾问配置" prop="configureId">
            <el-select v-model="queryParams.configureId" filterable placeholder="选择配置" clearable
                       size="small">
              <el-option label="选择配置" value=""/>
              <el-option
                  v-for="con in configureList"
                  :value="con.id"
                  :key="con.id"
                  :label="con.name"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="时间筛选" prop="dateArr">
            <el-date-picker
                clearable
                v-model="dateArr"
                type="daterange"
                value-format="yyyy-MM-dd"
                align="right"
                size="small"
                unlink-panels
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                :picker-options="pickerOptions">
            </el-date-picker>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery">搜索
            </el-button>
            <el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
          </el-form-item>
        </el-form>

        <el-row :gutter="10" class="mb8">
          <el-col :span="1.5">
            <el-button
                v-permission="'sys:user:add'"
                type="primary"
                plain
                icon="el-icon-plus"
                size="mini"
                @click="handleAdd"
            >新增
            </el-button>
          </el-col>
          <el-col :span="1.5">
            <el-button
                v-permission="'sys:user:setAdviser'"
                type="primary"
                size="mini"
                @click="setAdviser"
            >设为学习顾问
            </el-button>
          </el-col>
          <el-col :span="1.5">
            <el-button
                v-permission="'sys:user:notAdviser'"
                type="primary"
                size="mini"
                @click="notAdviser"
            >取消学习顾问
            </el-button>
          </el-col>
          <el-col :span="1.5">
            <el-button
                v-permission="'sys:user:dimission'"
                type="primary"
                size="mini"
                @click="resign"
            >用户离职
            </el-button>
          </el-col>
        </el-row>

        <el-table :data="userList" @selection-change="handleSelectionChange" max-height="540">
          <el-table-column type="selection" width="50" align="center"/>
          <el-table-column label="主键" align="center" prop="id" width="50"/>
          <el-table-column label="用户昵称" align="center" prop="nickName" fixed="left"
                           :show-overflow-tooltip="true"/>
          <el-table-column label="登录名称" align="center" prop="userName"
                           :show-overflow-tooltip="true"/>
          <el-table-column align="center" label="拥有角色" prop="roleList" width="120"
                           :show-overflow-tooltip="true">
            <template slot-scope="scope">
                            <span class="text-shenglue" v-for="(s, i) in scope.row.roleList || []" :key="i">{{
                                s
                              }}</span>
            </template>
          </el-table-column>
          <el-table-column label="部门" align="center" prop="deptName" width="150"
                           :show-overflow-tooltip="true"/>
          <el-table-column label="学习顾问" align="center" prop="isAdviser">
            <template slot-scope="scope">
              {{ scope.row.isAdviser === 1 ? '是' : '否' }}
            </template>
          </el-table-column>
          <el-table-column label="顾问配置" align="center" prop="configureId">
            <template v-slot="scope">
              <span v-for="item in configureList" :key="item.id"
                    v-if="item.id === scope.row.configureId">{{ item.name }}</span>
            </template>
          </el-table-column>
          <el-table-column label="状态" align="center" width="120">
            <template slot-scope="scope">
                            <span v-for="status in statusOptions"
                                  v-if="scope.row.status === status.value">{{ status.label }}</span>
            </template>
          </el-table-column>
          <el-table-column label="创建时间" align="center" prop="createTime" width="150"/>
          <el-table-column
              label="操作"
              fixed="right"
              align="center"
              width="160"
              class-name="small-padding fixed-width"
          >
            <template v-slot="scope">
              <el-button
                  v-permission="'sys:user:update'"
                  size="mini"
                  type="text"
                  icon="el-icon-edit"
                  @click="handleUpdate(scope.row)"
              >修改
              </el-button>
              <el-button
                  v-permission="'sys:user:passwordResetEdit'"
                  size="mini"
                  type="text"
                  icon="el-icon-key"
                  @click="handleResetPwd(scope.row)"
              >重置
              </el-button>
              <el-button
                  v-permission="'sys:user:encode'"
                  v-if="!scope.row.encode"
                  size="mini"
                  type="text"
                  icon="el-icon-circle-close"
                  @click="encodephone(scope.row.id)"
              >关闭加密
              </el-button>
              <el-button
                  v-permission="'sys:user:encode'"
                  v-if="scope.row.encode"
                  size="mini"
                  type="text"
                  icon="el-icon-circle-check"
                  @click="encodephone(scope.row.id)"
              >开启加密
              </el-button>
              <el-button
                  v-permission="'sys:user:delete'"
                  size="mini"
                  type="text"
                  icon="el-icon-key"
                  @click="handleDelete(scope.row)"
              >删除
              </el-button>
            </template>
          </el-table-column>
        </el-table>

        <pagination
            v-show="total>0"
            :total="total"
            :page.sync="queryParams.page"
            :limit.sync="queryParams.limit"
            @pagination="getList"
        />
      </el-col>
    </el-row>

    <!-- 添加或修改参数配置对话框 -->
    <el-dialog :title="title" :visible.sync="open" width="850px" append-to-body>
      <el-form ref="form" :model="form" :rules="rules" label-width="120px">
        <el-row>

          <el-col :span="12">
            <el-form-item label="归属部门" prop="deptId">
              <el-select v-model="form.deptId" filterable placeholder="请选择">
                <el-option
                    v-for="dept in deptSelect"
                    :key="dept.id"
                    :label="dept.deptName"
                    :value="dept.id"
                    :value-key="dept.id"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="用户昵称" prop="nickName">
              <el-input v-model="form.nickName" placeholder="请输入用户昵称"/>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="手机号码" prop="phonenumber">
              <el-input v-model="form.telephone" placeholder="请输入手机号码" maxlength="11"/>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="登陆账号" prop="userName">
              <el-input v-model="form.userName" placeholder="请输入用户名称"/>
            </el-form-item>
          </el-col>
          <el-col :span="12" v-if="form.id === undefined">
            <el-form-item label="用户密码" prop="password">
              <el-input v-model="form.password" placeholder="请输入用户密码" type="password"/>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="角色" prop="roleList">
              <el-select v-model="form.roleList" filterable multiple placeholder="请选择">
                <el-option
                    v-for="item in roleOptions"
                    :key="item.id"
                    :label="item.roleName"
                    :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="状态">
              <el-radio-group v-model="form.status">
                <el-radio v-for="status in statusOptions" :value="status.value"
                          :key="status.value" :label="status.value">{{ status.label }}
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="12">
            <el-form-item label="设为管理者">
              <el-switch :active-value="1" :inactive-value="0" v-model="form.isAdmin"
                         @input="changeIsAdmin"></el-switch>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="数据管理范围">
<!--              <el-radio-group :disabled="!(form.isAdmin === 1)" size="mini" v-model="form.dataScope">-->
<!--                <el-radio v-for="security in securityArr" :label="security.value">-->
<!--                  <el-tooltip effect="dark" :content="security.tip" placement="top-start">-->
<!--                    <span>{{ security.label }}<i class="el-icon-warning"/></span>-->
<!--                  </el-tooltip>-->
<!--                </el-radio>-->
<!--              </el-radio-group>-->
              <el-radio-group size="mini" v-model="form.dataPermission">
                <el-radio v-for="security in securityArr" :label="security.value">
                  <el-tooltip effect="dark" :content="security.tip" placement="top-start">
                    <span>{{ security.label }}<i class="el-icon-warning"/></span>
                  </el-tooltip>
                </el-radio>
              </el-radio-group>
              <template #label>
                <el-tooltip effect="dark" content="数据可查看和管理的范围" placement="top">
                  <span>数据管理范围<i class="el-icon-warning"/></span>
                </el-tooltip>
              </template>

            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <!--          <el-col :span="12">-->
          <!--            <el-form-item label="数据加密">-->
          <!--              <el-switch :active-value="true" active-text="不加密" :inactive-value="false" inactive-text="加密"-->
          <!--                         v-model="form.encode"></el-switch>-->
          <!--            </el-form-item>-->
          <!--          </el-col>-->
          <el-col :span="12">
            <el-form-item label="隐藏首咨">
              <el-switch :active-value="true" :inactive-value="false" v-model="form.hideFirst"></el-switch>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="每日可分配数">
              <el-input type="number" v-model="form.vallot"></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="12">
            <el-form-item label="设为学习顾问">
              <el-switch :active-value="1" :inactive-value="0" v-model="form.isAdviser"
                         @change="changeIsAdviser"></el-switch>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="顾问配置">
              <el-select v-model="form.configureId" filterable size="small"
                         :disabled="!(form.isAdviser === 1)">
                <el-option v-for="con in configureList" :key="con.id" :value="con.id"
                           :label="con.name">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>

      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitForm">确 定</el-button>
        <el-button @click="cancel">取 消</el-button>
      </div>
    </el-dialog>
    <el-dialog :visible.sync="upload.open" :title="upload.title" width="400px" append-to-body>
      <el-upload
          ref="upload"
          :limit="1"
          accept=".xlsx, .xls"
          :headers="upload.headers"
          :action="upload.url"
          :disabled="upload.isUploading"
          :on-progress="handleFileUploadProgress"
          :on-success="handleFileSuccess"
          :auto-upload="false"
          drag
      >
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">
          将文件拖到此处，或
          <em>点击上传</em>
        </div>
        <div class="el-upload__tip" slot="tip">
          <el-link type="info" style="font-size:20px" @click="importTemplate">下载模板</el-link>
        </div>
        <div class="el-upload__tip" style="color:red" slot="tip">提示：仅允许导入“xls”或“xlsx”格式文件！</div>
      </el-upload>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitFileForm">确 定</el-button>
        <el-button @click="upload.open = false">取 消</el-button>
      </div>
    </el-dialog>

    <el-dialog title="设置学习顾问" width="400px" :visible.sync="adviserDialog" append-to-body>
      <el-select v-model="adviserParams.configureId">
        <el-option v-for="item in configureList" :key="item.id" :label="item.name" :value="item.id"></el-option>
      </el-select>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="appointAdviser">确 定</el-button>
        <el-button @click="adviserDialog = false">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import * as deptApi from "@/api/system/dept";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import * as accountApi from "@/api/system/account";
import * as roleApi from "@/api/system/role";
import * as configApi from "@/api/system/config";
import * as api from "@/utils/api";
import axios from "axios";

export default {
  name: "Account",
  components: {},
  data() {
    return {
      adviserParams: {},
      adviserDialog: false,
      // 遮罩层
      loading: true,
      // 选中数组
      ids: [],
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      // 显示搜索条件
      showSearch: true,
      // 总条数
      total: 0,
      // 用户表格数据
      userList: [],
      // 弹出层标题
      title: "",
      // 部门树选项
      deptOptions: [],
      // 是否显示弹出层
      open: false,
      // 部门名称
      deptName: null,
      // 默认密码
      initPassword: null,
      // 日期范围
      dateRange: [],
      // 状态数据字典
      statusOptions: [
        {value: 1, label: "正常"},
        {value: 2, label: "离职"},
        {value: 0, label: "暂停"}
      ],
      // 角色选项
      roleOptions: [],
      // 表单参数
      form: {
        deptId: 1,
        dataPermission: 'PERSONAL',
      },
      deptId: null,
      defaultProps: {
        children: "children",
        label: "title"
      },
      // 查询参数
      queryParams: {
        page: 1,
        limit: 10,
        status: 1
      },
      dateArr: [],
      // 表单校验
      rules: {
        userName: [
          {required: true, message: "用户名称不能为空", trigger: "blur"}
        ],
        nickName: [
          {required: true, message: "用户昵称不能为空", trigger: "blur"}
        ],
        roleList: [
          {required: true, message: "角色不能为空", trigger: "change"}
        ]
      },
      deptSelect: [],
      token: "",
      upload: {
        open: false,
        title: "",
        isUploading: false,
        url: `/api/manage/system/sysAccount/importAccount`,
          headers:{
              "Content-Type": "application/json;charset=utf-8",
              "version": localStorage.getItem("_version"),
          }
      },
      securityArr: [
        // {label: "全部", value: 1, tip: "所有数据"},
        // {label: "本部门", value: 2, tip: "部门拥有所有数据"},
        // {label: "仅小组", value: 3, tip: "仅本小组被领取数据"},
        // {label: "仅小组数据", value: 4, tip: "仅本小组领取数据"},
        {label: "全部", value: 'ALL', tip: "所有数据"},
        {label: "整部门", value: 'OVER_DEPT', tip: "看见本部门下的人员数据,包含子部门"},
        {label: "仅部门", value: 'ONLY_DEPT', tip: "看见本部门下的人员数据,不包含子部门"},
        {label: "仅个人", value: 'PERSONAL', tip: "只能看见自己的数据"}
      ],
      aaa: "",
      encode: "",
      configureList: [],
      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近三个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        }]
      },
    };
  },
  watch: {
    // 根据名称筛选部门树
    deptName(val) {
      this.$refs.tree.filter(val);
    }
  },
  created() {
    this.getList();
    this.getTreeselect();
    this.getDeptList();
    this.deptId = 1;
    this.getEncodeStatus();
    //获取顾问配置下拉框
    api.configureList(1, 99999, {parentId: -1}).then(res => {
      this.configureList = res.data.data;
    });

  },
  methods: {

    encodephone(id) {
      accountApi.encodePhone(id).then(res => {
        this.$message.success("修改成功!")
        this.getList();
      })
    },
    getEncodeStatus() {
      configApi.findConfigByConfigType("TELEPHONE_ENCODE").then(res => {
        if (res) {
          this.encode = res['encode']
        }
      })
    },
    getDeptList() {
      deptApi.list().then(res => {
        this.deptSelect = res.data;
      });
    },
    /** 查询用户列表 */
    getList() {
      this.loading = true;
      if (this.dateArr) {
        console.log(this.dateArr, 111)
        this.queryParams.startDate = this.dateArr[0]
        this.queryParams.endDate = this.dateArr[1]
      } else {
        this.queryParams.startDate = null
        this.queryParams.endDate = null
      }
      accountApi.listAccount(this.queryParams).then(response => {
            this.userList = response.data.records;
            this.total = response.data.total;
            this.loading = false;
          }
      );
    },
    /** 查询部门下拉树结构 */
    getTreeselect() {
      deptApi.treeDept().then(response => {
        this.deptOptions = response.data;
      });
      roleApi.list().then(res => {
        this.roleOptions = res.data
      })
    },
    // 筛选节点
    filterNode(value, data) {
      if (!value) return true;
      return data.label.indexOf(value) !== -1;
    },
    // 节点单击事件
    handleNodeClick(data) {
      this.queryParams.inDept = data.id;
      this.deptId = data.id;
      this.getList();
    },
    // 取消按钮
    cancel() {
      this.open = false;
      this.reset();
    },
    // 表单重置
    reset() {
      this.form = {};
      this.resetForm("form");
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.page = 1;
      this.getList();
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.dateRange = [];
      this.dateArr = [];
      this.resetForm("queryForm");
      this.handleQuery();
    },
    // 多选框选中数据
    handleSelectionChange(selection) {
      this.ids = selection.map(item => item.id);
      this.single = selection.length !== 1;
      this.multiple = !selection.length;
    },
    /** 新增按钮操作 */
    handleAdd() {
      this.form = {
        status: 1,
        dataScope: ''
      }
      this.open = true;
      this.title = "添加用户";
      this.$set(this.form, 'deptId', this.deptId)
    },
    /** 修改按钮操作 */
    handleUpdate(row) {
      this.reset();
      const id = row.id
      accountApi.getAccount(id).then(response => {
        this.form = response.data;
        this.open = true;
        this.title = "修改用户";
      });
    },
    /** 删除用户 */
    handleDelete(row) {
      this.reset();
      const id = row.id
      accountApi.deleteAccount(id).then(response => {
        if (response.success) {
          this.msgSuccess("删除用户成功");
        }
        this.getList();
      });
    },
    /** 重置密码按钮操作 */
    handleResetPwd(row) {
      this.$prompt('请输入"' + row.nickName + '"的新密码', "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消"
      }).then(({value}) => {
        accountApi.passwordResetUpdate(row.id, value).then(response => {
          if (response.success) {
            this.msgSuccess("修改成功");
          }
        });
      });
    },
    /** 提交按钮 */
    submitForm() {
      //校验管理员
      if (!(this.form.isAdmin === 1)) {
        //如果不是管理员，重置管理员的数据查看范围为0
        this.form.dataScope = 0
      }
      this.$refs["form"].validate(valid => {
        if (valid) {
          if (this.form.id !== undefined) {
            accountApi.updateAccount(this.form).then(response => {
              if (response.success) {
                this.msgSuccess("修改成功");
                this.open = false;
                this.getList();
              } else {
                this.$message.warning(response.msg)
              }
            });
          } else {
            if (!this.form.password) {
              this.$message.error("密码未填写");
              return;
            }
            accountApi.saveAccount(this.form).then(response => {
              if (response.success) {
                this.msgSuccess("新增成功");
                this.open = false;
                this.getList();
              } else {
                this.$message.warning(response.msg)
              }
            });
          }
        }
      });
    },
    /** 导入按钮操作 */
    handleImport() {
      this.upload.title = "用户导入";
      this.upload.open = true;
    },
    /** 导出按钮操作 */
    handleExport() {
      const loading = this.$loading({
        lock: true,
        text: '玩命加载中...请稍后...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      if (this.dateArr) {
        this.queryParams.startDate = this.dateArr[0]
        this.queryParams.endDate = this.dateArr[1]
      } else {
        this.queryParams.startDate = null
        this.queryParams.endDate = null
      }
      axios({
        method: 'post',
        url: `/api/manage/system/sysAccount/export`,
        params: this.queryParams,
        responseType: "blob",
        headers: {
          "Content-Type": "application/json;charset=utf-8",
            "version": localStorage.getItem("_version"),
        },
      }).then(res => {
        let now = new Date();
        const blob = new Blob([res.data]);
        const fileName = '用户数据' + now.getFullYear() + now.getMonth() + 1 + now.getDate() + '.xlsx';
        const elink = document.createElement('a')
        elink.download = fileName;
        elink.style.display = 'none';
        elink.href = URL.createObjectURL(blob);
        document.body.appendChild(elink);
        elink.click();
        URL.revokeObjectURL(elink.href); // 释放URL 对象
        document.body.removeChild(elink);
        loading.close();
      })
    },
    /** 下载模板操作 */
    async importTemplate() {
      let url = '../excel/user_template.xlsx';
      const res = await fetch(url)
      const blob = await res.blob()
      const elink = document.createElement('a')
      elink.download = url.substr(url.lastIndexOf("/") + 1);
      elink.style.display = 'none'
      elink.href = window.URL.createObjectURL(blob)
      document.body.appendChild(elink)
      elink.click()
      window.URL.revokeObjectURL(elink.href) // 释放URL 对象
      document.body.removeChild(elink)
    },
    // 文件上传中处理
    handleFileUploadProgress(event, file, fileList) {
      this.upload.isUploading = true;
    },
    // 文件上传成功处理
    handleFileSuccess(response, file, fileList) {
      this.upload.open = false;
      this.upload.isUploading = false;
      this.$refs.upload.clearFiles();
      this.$alert(response.msg, "导入结果", {dangerouslyUseHTMLString: true});
      this.getList();
    },
    // 提交上传文件
    submitFileForm() {
      this.$refs.upload.submit();
    },
    resign(row) {
      const ids = row.id || this.ids;
      accountApi.resign(ids).then(response => {
        if (response.success) {
          this.msgSuccess("人员离职成功");
          this.open = false;
          this.getList();
        } else {
          this.$message.warning(response.msg)
        }
      });
    },
    appointAdviser() {
      if (!this.adviserParams.configureId) {
        this.$message.error("请选择顾问配置!")
        return;
      }
      const params = {}
      params.configureId =  this.adviserParams.configureId;
      params.configMarketId =  this.adviserParams.configMarketId;
      accountApi.setAdviser(params, this.adviserParams.ids).then(response => {
        if (response.success) {
          this.msgSuccess("设置学习顾问成功");
          this.open = false;
          this.adviserDialog = false
          this.getList();
        } else {
          this.$message.warning(response.msg)
        }
      });
    },
    notAdviser(row) {
      const ids = row.id || this.ids;
      accountApi.notAdviser(ids).then(response => {
        if (response.success) {
          this.msgSuccess("取消学习顾问成功");
          this.open = false;
          this.getList();
        } else {
          this.$message.warning(response.msg)
        }
      });
    },
    setAdviser(row) {
      const ids = this.ids;
      if (!ids || ids.length <= 0) {
        this.$message.error("请选择您想设为学习顾问的用户!")
        return
      }
      this.adviserParams.ids = ids
      this.adviserDialog = true
    },
    changeIsAdviser() {
      if (this.form.isAdviser === 0) {
        this.form.configureId = null
      }
    },
    changeIsAdmin() {
      if (this.form.isAdmin === 0) {
        this.form.dataScope = null
      }
    },
  }
};
</script>
<style scoped>
.text-shenglue {
  display: block;
  word-break: keep-all;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
  -icab-text-overflow: ellipsis;
  -khtml-text-overflow: ellipsis;
  -moz-text-overflow: ellipsis;
  -webkit-text-overflow: ellipsis;
}

/deep/ .el-tree-node.is-current > .el-tree-node__content {
  background-color: #164bff !important;
  color: #eeeeee !important;
}
</style>
