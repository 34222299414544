<template>
  <div>
    <el-descriptions title="学员信息" :column="3" size="small" border>
      <template slot="extra" v-if="edit">
        <el-button type="primary" size="mini" @click="handleEdit">编辑</el-button>
      </template>
      <el-descriptions-item>
        <template slot="label">
          学员姓名
        </template>
        {{ studentInfo.studentName }}
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          学员类型
        </template>
        <span v-for="item in studentTypeList" :key="item.id"
              v-if="studentInfo.studentType === item.dictValue">{{ item.dictLabel }}</span>
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          性别
        </template>
        {{ studentInfo.sex }}
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          当前服务号
        </template>
        <span v-for="item in serviceList" :key="item.servicesId"
              v-if="studentInfo.servicesId === item.servicesId">{{ item.name }}</span>
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          首次下单手机号
        </template>
        {{ studentInfo.mobile }}
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          开课手机号
        </template>
        {{ studentInfo.openMobile }}
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          当前服务科目
        </template>
        <span v-for="item in serviceSubjectList" :key="item.id"
              v-if="studentInfo.subject === item.dictValue">{{ item.dictLabel }}</span>
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          首次沟通时间
        </template>
        {{ studentInfo.firstTime }}
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          工作年限
        </template>
        {{ studentInfo.working }}
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          最高学历
        </template>
        {{ studentInfo.education }}
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          学员备注
        </template>
        {{ studentInfo.remarks }}
      </el-descriptions-item>
    </el-descriptions>
    <el-descriptions title="服务信息" :column="3" size="small" border>
      <el-descriptions-item>
        <template slot="label">
          是否加企微
        </template>
        {{ studentInfo.weworked ? '是' : '否' }}
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          是否加钉
        </template>
        {{ studentInfo.dingding ? '是' : '否' }}
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          是否进入架构
        </template>
        {{ studentInfo.framework ? '是' : '否' }}
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          是否进群
        </template>
        {{ studentInfo.crowd ? '是' : '否' }}
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          是否建档
        </template>
        {{ studentInfo.record ? '是' : '否' }}
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          是否报考
        </template>
        {{ studentInfo.enroll ? '是' : '否' }}
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          报考省份
        </template>
        {{ studentInfo.signProvince }}
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          是否有施工经验
        </template>
        {{ studentInfo.experienced ? '是' : '否' }}
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          考证目的
        </template>
        {{ studentInfo.aim }}
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          是否第一次参加
        </template>
        {{ studentInfo.firsted ? '是' : '否' }}
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          第几次考试
        </template>
        {{ studentInfo.restartNum }}
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          是否从事相关工作
        </template>
        {{ studentInfo.worked ? '是' : '否' }}
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          每周学习时间
        </template>
        {{ studentInfo.learnTimeWeek }}
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          收货地址
        </template>
        {{ studentInfo.address }}
      </el-descriptions-item>
    </el-descriptions>
    <el-form v-model="studentInfo" label-width="150px" size="mini">
      <el-divider></el-divider>
      <el-row style="padding-left: 20px;color: #409EFF;font-size: 14px;">订单信息</el-row>
      <el-tabs v-model="nowTab" tab-position="left" style="min-height: 100px">
        <el-tab-pane v-for="item in orderList" :label="item.goodsName" :name="item.orderId">
          <el-form-item label="商品名称" label-width="100px">
            <template #default>
              <el-row>
                <el-col :span="18">
                  {{ item.goodsName }}
                </el-col>
                <el-col :span="6"  v-if="edit">
                  <el-button type="info" v-permission="'waiter:order:delete'" @click="delOrder(item.orderId)">删除订单
                  </el-button>
                </el-col>
              </el-row>
            </template>
          </el-form-item>
          <el-form-item label="成单类型" label-width="100px">
                        <span v-for="o in pondList" :key="o.id"
                              v-if="item.orderType === o.id">{{ o.name }}</span>
          </el-form-item>
          <el-form-item label="下单渠道" label-width="100px">
                        <span v-for="o in orderChannelList" :key="o.id"
                              v-if="item.orderChannel === o.dictValue">{{ o.dictLabel }}</span>
          </el-form-item>
          <el-form-item label="成单销售" label-width="100px">
            {{ item.orderSalesName }}
          </el-form-item>
          <el-form-item label="下单手机号" label-width="100px">
            {{ item.mobile }}
          </el-form-item>
          <el-form-item label="购买时间" label-width="100px">
            {{ item.buyTime }}
          </el-form-item>
          <el-form-item label="订单号" label-width="100px">
            {{ item.orderNo }}
          </el-form-item>
          <el-form-item label="下单金额" label-width="100px">
            {{ item.orderPrice ? item.orderPrice / 100 : '' }}
          </el-form-item>
        </el-tab-pane>
      </el-tabs>
    </el-form>

    <el-dialog title="修改数据" :visible.sync="dataFromSync" width="80%" top="20px" append-to-body>
      <el-form :model="studentInfo" :rules="dataFromRules" ref="dataFrom" label-width="150px"
               size="medium" :inline="true">
        <el-form-item label="学员姓名" prop="studentName">
          <el-input v-model="studentInfo.studentName"></el-input>
        </el-form-item>
        <el-form-item label="学员类型">
          <el-select v-model="studentInfo.studentType">
            <el-option v-for="item in studentTypeList" :key="item.id" :value="item.dictValue"
                       :label="item.dictLabel"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="首次沟通时间" prop="firstTime">
          <el-date-picker
              style="width: 190px;"
              v-model="studentInfo.firstTime"
              value-format="yyyy-MM-dd HH:mm:ss"
              type="datetime"
              placeholder="选择日期时间">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="当前服务科目" prop="subject">
          <el-select v-model="studentInfo.subject">
            <el-option v-for="item in serviceSubjectList " :key="item.id" :label="item.dictLabel"
                       :value="item.dictValue"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="开课手机号" prop="realOpenMobile">
          <el-input v-model="studentInfo.openMobile"></el-input>
        </el-form-item>
        <el-form-item label="工作年限" prop="working">
          <el-input v-model="studentInfo.working"></el-input>
        </el-form-item>
        <el-form-item label="最高学历" prop="education">
          <el-input v-model="studentInfo.education"></el-input>
        </el-form-item>
        <el-form-item label="性别" prop="sex">
          <el-select v-model="studentInfo.sex">
            <el-option value="男" label="男"></el-option>
            <el-option value="女" label="女"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="报名省份" prop="signProvince">
          <el-input v-model="studentInfo.signProvince"></el-input>
        </el-form-item>
        <el-form-item label="考证目的" prop="aim">
          <el-input v-model="studentInfo.aim"></el-input>
        </el-form-item>
        <el-form-item label="每周学习时长" prop="learnTimeWeek">
          <el-input v-model="studentInfo.learnTimeWeek"></el-input>
        </el-form-item>
        <el-form-item label="第几次参加考试" prop="restartNum">
          <el-input-number :step-strictly="true" :step="1" :min="0" :max="999" v-model="studentInfo.restartNum"></el-input-number>
        </el-form-item>
        <el-form-item label="收货地址" prop="address">
          <el-input v-model="studentInfo.address" type="textarea" :rows="4"></el-input>
        </el-form-item>
        <el-form-item label="学员备注" prop="remarks">
          <el-input v-model="studentInfo.remarks" type="textarea" :rows="4"></el-input>
        </el-form-item>
        <el-divider></el-divider>
        <el-form-item label="是否加企微" prop="weworked">
          <el-switch
              v-model="studentInfo.weworked"
              active-text="是"
              inactive-text="否">
          </el-switch>
        </el-form-item>
        <el-form-item label="是否加钉" prop="dingding">
          <el-switch
              v-model="studentInfo.dingding"
              active-text="是"
              inactive-text="否">
          </el-switch>
        </el-form-item>
        <el-form-item label="是否进入架构" prop="framework">
          <el-switch
              v-model="studentInfo.framework"
              active-text="是"
              inactive-text="否">
          </el-switch>
        </el-form-item>
        <el-form-item label="是否进群" prop="crowd">
          <el-switch
              v-model="studentInfo.crowd"
              active-text="是"
              inactive-text="否">
          </el-switch>
        </el-form-item>
        <el-form-item label="是否建档" prop="record">
          <el-switch
              v-model="studentInfo.record"
              active-text="是"
              inactive-text="否">
          </el-switch>
        </el-form-item>
        <el-form-item label="是否报考" prop="enroll">
          <el-switch
              v-model="studentInfo.enroll"
              active-text="是"
              inactive-text="否">
          </el-switch>
        </el-form-item>
        <el-form-item label="是否有施工经验" prop="experienced">
          <el-switch
              v-model="studentInfo.experienced"
              active-text="是"
              inactive-text="否">
          </el-switch>
        </el-form-item>
        <el-form-item label="是否第一次参加考试" prop="firsted">
          <el-switch
              v-model="studentInfo.firsted"
              active-text="是"
              inactive-text="否">
          </el-switch>
        </el-form-item>
<!--        <el-form-item label="是否发送协议" prop="sendProtocol">-->
<!--          <el-switch-->
<!--              v-model="studentInfo.sendProtocol"-->
<!--              active-text="是"-->
<!--              inactive-text="否">-->
<!--          </el-switch>-->
<!--        </el-form-item>-->
<!--        <el-form-item label="是否签署协议" prop="signProtocol">-->
<!--          <el-switch-->
<!--              v-model="studentInfo.signProtocol"-->
<!--              active-text="是"-->
<!--              inactive-text="否">-->
<!--          </el-switch>-->
<!--        </el-form-item>-->
        <el-form-item label="是否从事相关工作" prop="worked">
          <el-switch
              v-model="studentInfo.worked"
              active-text="是"
              inactive-text="否">
          </el-switch>
        </el-form-item>

      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" size="medium" @click="submitForm('dataFrom')">确 定</el-button>
        <el-button size="medium" @click="cancel">取 消</el-button>
      </div>
    </el-dialog>

    <el-dialog title="修改详情" :visible.sync="infoFromSync" width="60%" top="20px" append-to-body>
      <el-form :model="studentInfo" :rules="infoFromRules" ref="infoFrom" style="width: 500px" label-width="150px"
               size="medium">
        <el-form-item label="学员类型" prop="studentType">
          <el-select v-model="studentInfo.studentType">
            <el-option v-for="item in studentTypeList " :key="item.id" :label="item.dictLabel"
                       :value="item.dictValue"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="学员姓名" prop="studentName">
          <el-input v-model="studentInfo.studentName"></el-input>
        </el-form-item>
        <el-form-item label="学员备注" prop="remarks">
          <el-input v-model="studentInfo.remarks" type="textarea" :rows="4"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" size="medium" @click="submitForm('infoFrom')">确 定</el-button>
        <el-button size="medium" @click="cancel">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import * as studentApi from "@/api/waiter/waiterStudent"
import * as serviceApi from "@/api/waiter/waiterServices"
import * as accountApi from "@/api/system/account"
import * as pondApi from "@/api/clue/cluePond"
import * as dictionaryApi from "@/api/system/dictionary"
import {getOrderByStudentId} from "@/api/waiter/waiterStudent";

export default {
  name: "WaiterStudentDetailRecord",
  props: {
    studentInfo: {
      type: Object,
      default: () => {
      }
    },
    edit: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      infoFromSync: false,
      infoFromRules: {},
      studentTypeList: [],
      serviceSubjectList: [],
      orderChannelList: [],
      pondList: [],
      accountList: [],
      serviceList: [],
      dataFromSync: false,
      dataFromRules: {},
      nowTab: '',
      orderList: [],
    }
  },
  mounted() {
    this.findService()
    this.findAccount()
    this.findPond()
    this.findStudentType()
    this.findOrderChannel()
    this.findOrder()
    this.findServiceSubject()
  },
  methods: {
    /**
     * 修改详情
     */
    handleInfo(row) {
      this.infoFromSync = true;
    },
    /**
     * 删除订单
     * @param orderId
     */
    delOrder(orderId) {
      studentApi.deleteOrder(orderId).then(res => {
        if (res.success) {
          this.$message.success(res.msg)
          this.findOrder()
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    findOrder() {
      studentApi.getOrderByStudentId(this.studentInfo.studentId).then(res => {
        this.orderList = res.data
        if (this.orderList.length > 0) {
          this.nowTab = this.orderList[0].orderId
        }
      })
    },
    handleEdit() {
      this.dataFromSync = true
    },
    /** 展示文字:如果没有则展示-- */
    showText(val) {
      return val ? val : '--'
    },
    /**
     * 取消
     */
    cancel() {
      this.infoFromSync = false
      this.dataFromSync = false
    },
    /**
     * 保存
     */
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          studentApi.saveData(this.studentInfo).then(res => {
            if (res.success) {
              this.$message({
                type: "success",
                message: '保存成功！'
              });
              this.dataFromSync = false
              this.infoFromSync = false
            } else {
              this.$message({
                type: "error",
                message: res.msg
              });
            }
          })
        } else {
          return false;
        }
      });
    },
    findStudentType() {
      dictionaryApi.findChildrenByCode('studentType').then(res => {
        if (res.success) {
          this.studentTypeList = res.data
        }
      })
    },
    findServiceSubject(){
      dictionaryApi.findChildrenByCode('serviceSubject').then(res => {
        if (res.success) {
          this.serviceSubjectList = res.data
        }
      })
    },
    findOrderChannel() {
      dictionaryApi.findChildrenByCode('orderChannel').then(res => {
        if (res.success) {
          this.orderChannelList = res.data
        }
      })
    },
    findPond() {
      pondApi.listCluePond().then(res => {
        if (res.success) {
          this.pondList = res.data
        }
        this.pondList.unshift({id: 1, name: '基础池'})
      })
    },
    findAccount() {
      accountApi.list().then(res => {
        if (res.success) {
          this.accountList = res.data
        }
      })
    },
    findService() {
      serviceApi.findList().then(res => {
        if (res.success) {
          this.serviceList = res.data
        }
      })
    },
    handleView(row) {
      studentApi.viewMobile(row.studentId).then(res => {
        if (res.success) {
          row.mobile = res.data
          row.showMobile = true
        } else {
          this.$message.error(res.msg)
        }
      })
    },
  }
}
</script>

<style scoped>
/deep/ .el-tabs--left .el-tabs__item.is-left {
  width: 147px;
}

/deep/ .el-descriptions__header {
  margin: 10px 20px 3px 20px;
}

</style>