<template>
    <div>
        <el-page-header @back="goBack" content="基本消息"></el-page-header>
        <el-form :model="dataFrom" :rules="dataFromRules" ref="dataFrom" style="width: 650px;" label-width="200px"
                 size="medium">
            <el-form-item label="按钮名称" prop="name">
                <template #label>
                    <el-tooltip effect="dark" content="不做展示使用,只作为记录"
                                placement="top">
                        <span>按钮名称<i class="el-icon-warning"/></span>
                    </el-tooltip>
                </template>
                <template #default>
                    <el-input v-model="dataFrom.name" placeholder="请输入按钮名称" type="text"></el-input>
                </template>
            </el-form-item>
            <el-form-item label="按钮展示" prop="label">
                <template #label>
                    <el-tooltip effect="dark" content="按钮文案"
                                placement="top">
                        <span>按钮展示<i class="el-icon-warning"/></span>
                    </el-tooltip>
                </template>
                <template #default>
                    <el-input v-model="dataFrom.label" placeholder="请输入按钮展示" type="text"></el-input>
                </template>
            </el-form-item>
            <el-form-item label="展示样式" prop="btnType">
                <template #label>
                    <el-tooltip effect="dark" content="展示时按钮的样式"
                                placement="top">
                        <span>展示样式<i class="el-icon-warning"/></span>
                    </el-tooltip>
                </template>
                <template #default>
                    <el-row>
                        <el-col :span="19">
                            <el-select v-model="dataFrom.btnType" style="width: 100%">
                                <el-option value="primary" label="蓝色"></el-option>
                                <el-option value="success" label="绿色"></el-option>
                                <el-option value="info" label="灰色"></el-option>
                                <el-option value="warning" label="橘色"></el-option>
                                <el-option value="danger" label="红色"></el-option>
                                <el-option value="" label="白色"></el-option>
                            </el-select>
                        </el-col>
                        <el-col :span="5" style="text-align: center">
                            <el-button :type="dataFrom.btnType">{{ dataFrom.label }}</el-button>
                        </el-col>
                    </el-row>
                </template>
            </el-form-item>
            <el-form-item label="功能描述" prop="remarks">
                <template #label>
                    <el-tooltip effect="dark" content="鼠标放在按钮上将会展示描述信息"
                                placement="top">
                        <span>功能描述<i class="el-icon-warning"/></span>
                    </el-tooltip>
                </template>
                <template #default>
                    <el-input v-model="dataFrom.remarks" placeholder="请输入按钮功能描述"
                              type="textarea"></el-input>
                </template>
            </el-form-item>
            <el-form-item label="分页数量" prop="size">
                <template #label>
                    <el-tooltip effect="dark" content="每次页面展示的数据量"
                                placement="top">
                        <span>分页数量<i class="el-icon-warning"/></span>
                    </el-tooltip>
                </template>
                <template #default>
                    <el-input v-model="dataFrom.size" placeholder="请输入分页数量" type="number"></el-input>
                </template>
            </el-form-item>
            <el-form-item label="领取等待时间(秒)" prop="receiveWait">
                <template #label>
                    <el-tooltip effect="dark" content="每次领取后需要等待多久才能再次领取"
                                placement="top">
                        <span>领取等待时间(秒)<i class="el-icon-warning"/></span>
                    </el-tooltip>
                </template>
                <template #default>
                    <el-input v-model="dataFrom.receiveWait" placeholder="请输入领取等待时间(秒)"
                              type="number"></el-input>
                </template>
            </el-form-item>
            <el-form-item label="默认每日领取上限" prop="receiveSize">
                <template #label>
                    <el-tooltip effect="dark" content="每日可从该按钮领取的上限"
                                placement="top">
                        <span>默认每日领取上限<i class="el-icon-warning"/></span>
                    </el-tooltip>
                </template>
                <template #default>
                    <el-input v-model="dataFrom.receiveSize" placeholder="请输入默认每日领取上限"
                              type="number"></el-input>
                </template>
            </el-form-item>
        </el-form>

        <el-divider content-position="left">动态配置</el-divider>
        <el-form :model="detailForm" :rules="detailFormRules" ref="detailFormRules" label-width="200px"
                 size="medium">

            <el-form-item label="配置项" prop="receiveSize" style="width: 650px">
                <template #default>
                    <el-row>
                        <el-col :span="19">
                            <el-select v-model="detailOpt" style="width: 100%">
                                <el-option v-for="(item,index) in detailList" :value="item.name"
                                           :label="item.label"></el-option>
                            </el-select>
                        </el-col>
                        <el-col :span="5" style="text-align: center">
                            <el-button type="success" @click="addDetail()">添加规则</el-button>
                        </el-col>
                    </el-row>
                </template>
            </el-form-item>
            <el-form-item v-for="(item,index) in detailArr" :key="item.code" :label="item.label" :prop="item.code">
                <ReceiveItem v-model="detailForm[item.code]" :item="item" :style="item.style"/>

                <el-button style="margin-left: 20px" type="info" @click="delDetail(index,item.code)">删除
                </el-button>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="submitForm()">保存</el-button>
                <el-button type="info" @click="goBack">取消</el-button>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
import * as receiveApi from "@/api/waiter/waiterReceiveBtn.js"
import ReceiveItem from "@/views/waiter/detail/ReceiveItem";

export default {
    name: "WaiterReceiveEdit",
    components: {ReceiveItem},
    data() {
        return {
            detailForm: {},
            detailFormRules: {},
            detailList: [
                {
                    name: "st.create_time",
                    label: "创建时间",
                    type: "date"
                },
                {
                    name: "st.student_type",
                    label: "学员类型",
                    type: "dict",
                    dict: 'studentType'
                },
                {
                    name: "o.order_type",
                    label: "成单类型",
                    type: "orderType",
                },
                {
                    name: "o.goods_name",
                    label: "商品名称",
                    type: "input"
                },
                {
                    name: "o.order_price",
                    label: "成单金额",
                    type: "money"
                },
                {
                    name: "o.order_channel",
                    label: "下单渠道",
                    type: "dict",
                    dict: 'orderChannel'
                },
                {
                    name: "o.buy_time",
                    label: "购买时间",
                    type: "date"
                },
            ],
            detailOpt: null,
            detailArr: [],
            statusList: [{label: '正常', value: 0}, {label: '禁用', value: 9}],
            dataFrom: {
                label: "领取",
                size: 10,
                receiveWait: 60,
                receiveSize: 10,
                btnType: 'primary'
            },
            dataFromRules: {
                receiveSize: [{required: true, message: '请输入默认每日领取上限', trigger: 'blur'}],
                name: [{required: true, message: '请输入标题', trigger: 'blur'}],
                receiveWait: [{required: true, message: '请输入领取等待时间', trigger: 'blur'}],
                size: [{required: true, message: '请输入分页数量', trigger: 'blur'}],
                // remarks: [{required: true, message: '请输入备注', trigger: 'blur'}],
                label: [{required: true, message: '请输入按钮名称', trigger: 'blur'}],
            },
            dataFromSync: false,
            dataFromAdd: true,
            comparisonTable: {
                "orderType": {
                    templateName: 'orderType',
                    prop: '',
                    type: 'orderType',
                    label: '',
                    placeholder: '',
                    multiple: true,
                    isShowDeleteBtn: true,
                    optionData: []
                },
                "dict": {
                    templateName: 'dict',
                    prop: '',
                    type: 'dict',
                    label: '',
                    placeholder: '',
                    multiple: true,
                    isShowDeleteBtn: true,
                    optionData: []
                },
                "date": {
                    templateName: 'date',
                    prop: '',
                    type: 'combo',
                    label: '',
                    isShowDeleteBtn: true,
                    comboList: [
                        {
                            prop: 'compare',
                            type: 'select',
                            placeholder: '请选择比较条件',
                            multiple: false,
                            optionData: [
                                {label: '<', value: 'LT'},
                                {label: '>', value: 'GT'}
                            ]
                        },
                        {
                            prop: 'compareType',
                            type: 'select',
                            placeholder: '请选择类型',
                            multiple: false,
                            optionData: [
                                {label: '日期选择', value: 0},
                                {label: '距离今日', value: 1}
                            ]
                        },
                        {
                            prop: 'date',
                            type: 'datePicker',
                            dateType: 'datetime',
                            placeholder: '请选择日期',
                            linkage: {
                                prop: 'compareType',
                                value: 0
                            }
                        },
                        {
                            prop: 'day',
                            type: 'input',
                            placeholder: '请填写距离今日多少天',
                            linkage: {
                                prop: 'compareType',
                                value: 1
                            }
                        }
                    ]
                },
                "select": {
                    templateName: 'select',
                    prop: '',
                    type: 'select',
                    label: '',
                    placeholder: '',
                    multiple: true,
                    isShowDeleteBtn: true,
                    optionData: []
                },
                "radio": {
                    templateName: 'radio',
                    prop: '',
                    type: 'select',
                    label: '',
                    placeholder: '',
                    multiple: true,
                    isShowDeleteBtn: true,
                    optionData: []
                },
                "input": {
                    templateName: 'input',
                    prop: '',
                    type: 'input',
                    label: '',
                    placeholder: '',
                    isShowDeleteBtn: true,
                    optionData: []
                },
                "select-like": {
                    templateName: 'select-like',
                    prop: '',
                    type: 'select',
                    label: '',
                    placeholder: '',
                    multiple: true,
                    isShowDeleteBtn: true,
                    optionData: []
                },
                // 学习进度使用
                "intention": {
                    templateName: 'intention',
                    prop: '',
                    type: 'combo',
                    label: '',
                    placeholder: '',
                    isShowDeleteBtn: true,
                    comboList: [
                        {
                            prop: 'compare',
                            type: 'select',
                            placeholder: '请选择类型',
                            multiple: false,
                            optionData: [
                                {label: '包含', value: 'IN'},
                                {label: '不包含', value: 'NOTIN'}
                            ]
                        },
                        {
                            prop: 'tag',
                            type: 'select',
                            placeholder: '请选择类型',
                            multiple: true,
                            optionData: []
                        },
                    ]
                },
                "number": {
                    templateName: 'number',
                    prop: '',
                    type: 'combo',
                    label: '',
                    placeholder: '',
                    isShowDeleteBtn: true,
                    comboList: [
                        {
                            prop: 'compare',
                            type: 'select',
                            placeholder: '请选择比较条件',
                            multiple: false,
                            optionData: [
                                {label: '=', value: 'EQ'},
                                {label: '<', value: 'LT'},
                                {label: '<=', value: 'LE'},
                                {label: '>=', value: 'GE'},
                                {label: '>', value: 'GT'},
                                {label: '动态范围', value: 'BETWEEN'},
                            ]
                        },
                        {
                            prop: 'start',
                            type: 'input',
                            placeholder: ''
                        },
                        {
                            prop: 'end',
                            type: 'input',
                            placeholder: '',
                            linkage: {
                                prop: 'compare',
                                value: 'BETWEEN'
                            }
                        }
                    ]
                },
                "money": {
                    templateName: 'money',
                    prop: '',
                    type: 'combo',
                    label: '',
                    placeholder: '',
                    isShowDeleteBtn: true,
                    comboList: [
                        {
                            prop: 'compare',
                            type: 'select',
                            placeholder: '请选择比较条件',
                            multiple: false,
                            optionData: [
                                {label: '=', value: 'EQ'},
                                {label: '<', value: 'LT'},
                                {label: '<=', value: 'LE'},
                                {label: '>=', value: 'GE'},
                                {label: '>', value: 'GT'},
                                {label: '动态范围', value: 'BETWEEN'},
                            ]
                        },
                        {
                            prop: 'start',
                            type: 'input',
                            placeholder: ''
                        },
                        {
                            prop: 'end',
                            type: 'input',
                            placeholder: '',
                            linkage: {
                                prop: 'compare',
                                value: 'BETWEEN'
                            }
                        }
                    ]
                },
            },
        }
    },
    mounted() {
        this.id = this.$route.query?.id
        this.init()
    },
    methods: {
        guid() {
            return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
                var r = Math.random() * 16 | 0,
                    v = c === 'x' ? r : (r & 0x3 | 0x8);
                return v.toString(16);
            });
        },
        setFromData(arr) {
            arr.map(item => {
                let code = this.addDetail(item.name)
                let params = {}
                switch (item.type) {
                    case 'time':
                        params.compare = item.compare
                        params.compareType = item.compareType
                        item.compareType === 1 ? params.day = item.value : params.date = item.value
                        break
                    case 'date':
                        params.compare = item.compare
                        params.compareType = item.compareType
                        item.compareType === 1 ? params.day = item.value : params.date = item.value
                        break
                    case 'input':
                        params = item.value || ''
                        break
                    case 'select':
                        params = item.value?.split(',') || []
                        break
                    case 'radio':
                        params = item.value?.split(',') || []
                        break
                    case 'select-like':
                        params = item.value?.split(',') || []
                        break
                    case 'number':
                        params.compare = item.compare
                        if (item.compare === 'BETWEEN') {
                            let v = item.value.split(",")
                            if (v) {
                                params.start = v[0]
                                params.end = v[1]
                            }
                        } else {
                            params.start = item.value
                        }
                        break
                    case 'money':
                        params.compare = item.compare
                        if (item.compare === 'BETWEEN') {
                            let v = item.value.split(",")
                            if (v) {
                                params.start = v[0]
                                params.end = v[1]
                            }
                        } else {
                            params.start = item.value
                        }
                        break
                    case 'intention':
                        params.compare = item.compare
                        params.tag = item.value?.split(',') || []
                        break
                    case 'dict':
                        params = item.value?.split(',') || []
                        break
                    case 'orderType':
                        params = item.value?.split(',').map(Number) || []
                        break
                }

                // this.detailForm[code] = params
                setTimeout(() => {
                    this.$nextTick(() => {
                        this.detailForm[code] = params
                    })
                }, 500)
            })
            console.log(this.detailForm)
        },
        init() {
            if (this.id) {
                receiveApi.info(this.id).then(res => {
                    if (res.success) {
                        this.dataFrom = res.data
                        if (res.data.ruleList) {
                            this.setFromData(res.data.ruleList)
                        }
                    } else {
                        this.$message.error(res.msg)
                    }
                })
            }
        },
        createSubmitData() {
            let childrenList = []
            this.detailArr.map(item => {
                let _res = {
                    name: item.prop,
                    label: item.label,
                    type: item.templateName,
                }
                switch (item.templateName) {
                    case 'time':
                        _res.compare = this.detailForm[item.code].compare
                        _res.compareType = this.detailForm[item.code].compareType
                        _res.value = _res.compareType === 1 ? this.detailForm[item.code].day : this.detailForm[item.code].date
                        break
                    case 'date':
                        _res.compare = this.detailForm[item.code].compare
                        _res.compareType = this.detailForm[item.code].compareType
                        _res.value = _res.compareType === 1 ? this.detailForm[item.code].day : this.detailForm[item.code].date
                        break
                    case 'input':
                        _res.compare = 'LIKE'
                        _res.value = this.detailForm[item.code] || ''
                        break
                    case 'select':
                        _res.compare = 'IN'
                        _res.value = (this.detailForm[item.code] || []).join(',')
                        break
                    case 'radio':
                        _res.compare = 'IN'
                        _res.value = (this.detailForm[item.code] || []).join(',')
                        break
                    case 'select-like':
                        _res.compare = 'LIKEIN'
                        _res.value = (this.detailForm[item.code] || []).join(',')
                        break
                    case 'number':
                        _res.compare = this.detailForm[item.code].compare
                        _res.value = _res.compare === 'BETWEEN' ? this.detailForm[item.code].start + "," + this.detailForm[item.code].end : this.detailForm[item.code].start
                        break
                    case 'money':
                        _res.compare = this.detailForm[item.code].compare
                        _res.value = _res.compare === 'BETWEEN' ? this.detailForm[item.code].start + "," + this.detailForm[item.code].end : this.detailForm[item.code].start
                        break
                    case 'intention':
                        _res.compare = this.detailForm[item.code].compare
                        _res.value = this.detailForm[item.code].tag.join(',')
                        break
                    case 'dict':
                        _res.code = this.detailForm[item.code].code
                        _res.compare = 'IN'
                        _res.value = (this.detailForm[item.code] || []).join(',')
                        _res.dict = this.detailForm[item.code].dict
                        break
                    case 'orderType':
                        _res.code = this.detailForm[item.code].code
                        _res.compare = 'IN'
                        _res.value = (this.detailForm[item.code] || []).join(',')
                        break
                }
                childrenList.push(_res)
            })
            this.dataFrom['ruleList'] = childrenList
        },
        submitForm() {
            this.$refs['dataFrom'].validate((valid) => {
                this.$refs['detailFormRules'].validate((validDetail) => {
                    if (valid && validDetail) {
                        this.createSubmitData()
                        console.log('最后提交数据:', this.dataFrom)
                        receiveApi.saveData(this.dataFrom).then(res => {
                            if (res.success) {
                                this.$message({
                                    type: "success",
                                    message: '保存成功！'
                                });
                                this.dataFromSync = false
                                this.goBack()
                            } else {
                                this.$message({
                                    type: "error",
                                    message: res.msg
                                });
                            }
                        })
                    } else {
                        return false;
                    }
                });
            });
        },
        delDetail(index, code) {
            this.detailArr.splice(index, 1)
            delete this.detailForm[code]
        },
        addDetail(name) {
            let c = name || this.detailOpt
            let opt = this.detailList.filter(item => item.name === c)[0] || {}
            let params = Object.assign({}, this.comparisonTable[opt.type])
            let code = this.guid()
            params.prop = opt.name
            params.code = code
            params.label = opt.label
            params.name = opt.name
            params.value = opt.value
            params.dict = opt.dict
            this.detailFormRules[code] = params.rules || []
            this.detailArr.push(params)
            return code
        },
        goBack() {
            //跳转到上一次浏览的页面
            this.$router.go(-1)
        },
    }
}
</script>

<style scoped>

</style>